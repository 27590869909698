import React, { useEffect, useState } from 'react'
import { create_utm, list_sources, list_tag } from '../../services/api'
import { addNotification, setLoading } from '../../store/actions'
import { v4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { TLanguages } from '../../models/ILocales'
import { LOCALES } from '../../locales/locales'
import { IListSource, IListTag, UTMData } from '../../models/IApiResponses'

const UTMModal = ({ onClose }: { onClose: () => void }) => {
    const [labelName, setLabelName] = useState<string>('')
    const [selectedSource, setSelectedSource] = useState<string>('')
    const [utmSource, setUtmSource] = useState<IListSource[]>([])
    const [utmTag, setUtmTag] = useState<IListTag[]>([])
    const [selectedTag, setSelectedTag] = useState<string>('')
    const [currentLocale] = useState<TLanguages>(
        localStorage.getItem('currentLocale') as TLanguages
    )
    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchUtmSource() {
            setLoading(true)
            try {
                const response = await list_sources()
                if (response.data) {
                    setUtmSource(response.data)
                }
            } catch (error) {
                dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: LOCALES[currentLocale].SERVER_ERROR
                    })
                )
            } finally {
                setLoading(false)
            }
        }

        fetchUtmSource()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetchUtmTag() {
            setLoading(true)
            try {
                const response = await list_tag()
                if (response.data) {
                    setUtmTag(response.data)
                }
            } catch (error) {
                dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: LOCALES[currentLocale].SERVER_ERROR
                    })
                )
            } finally {
                setLoading(false)
            }
        }

        fetchUtmTag()
        // eslint-disable-next-line
    }, [])

    const handleSave = async () => {
        try {
            let utmData: UTMData = {
                name: labelName,
                utm_source: selectedSource,
                utm_tag: selectedTag
            }

            //  if (selectedTag !== '') {
            //      utmData.utm_tag = null
            //  }

            await create_utm(utmData)
            onClose()
            dispatch(
                addNotification({
                    id: v4(),
                    type: 'Success',
                    msg: 'Успешно сохранено'
                })
            )
        } catch (error) {
            dispatch(
                addNotification({
                    id: v4(),
                    type: 'Error',
                    msg: 'Введите правильные значения'
                })
            )
        }
    }
    return (
        <div>
            <button
                onClick={onClose}
                className="px-2 py-0 rounded text-2xl cursor-pointer"
            >
                &#8592;
            </button>
            <div className="modal flex justify-center">
                <div className="modal-content">
                    <h2 className="font-bold text-center">
                        {LOCALES[currentLocale].CREATE_UTM}
                    </h2>

                    <label htmlFor="labelName" className="block mt-3 ">
                        {LOCALES[currentLocale].LABEL_NAME}
                        <input
                            type="text"
                            id="labelName"
                            value={labelName}
                            onChange={e => setLabelName(e.target.value)}
                            className="block w-full border border-blue-500 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 pl-1"
                            style={{ width: '250px' }}
                        />
                    </label>

                    <select
                        id="selectKey"
                        value={selectedSource}
                        onChange={e => setSelectedSource(e.target.value)}
                        style={{ width: '250px' }}
                        className="block w-full border border-blue-500 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50  mt-5"
                    >
                        <option value="" disabled>
                            {LOCALES[currentLocale].SELECT_SOURCE}
                        </option>
                        {utmSource.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.source}
                            </option>
                        ))}
                    </select>

                    <select
                        id="selectTag"
                        value={selectedTag}
                        onChange={e => setSelectedTag(e.target.value)}
                        style={{ width: '250px' }}
                        className="block w-full border border-blue-500 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mt-5"
                    >
                        <option value="" disabled>
                            {LOCALES[currentLocale].SELECT_TAG}
                        </option>
                        {utmTag.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.tag}
                            </option>
                        ))}
                    </select>

                    <div className="flex justify-center mt-2">
                        <button
                            className="bg-blue-500 text-white px-3 py-1 rounded mr-2 mt-5"
                            onClick={handleSave}
                        >
                            {LOCALES[currentLocale].SAVE}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UTMModal
