export const ACTION_SET_ADMIN_AUTH = 'ACTION_SET_ADMIN_AUTH'
export const ACTION_SET_GUEST_AUTH = 'ACTION_SET_GUEST_AUTH'
export const ACTION_SET_DOWNLOAD_URLS = 'ACTION_SET_DOWNLOAD_URLS'
export const ACTION_SET_LOADING = 'ACTION_SET_LOADING'
export const ACTION_SET_USER_REQUEST_LOADING = 'ACTION_SET_USER_REQUEST_LOADING'
export const ACTION_CHEK_AUTH = 'ACTION_CHECK_AUTH'
export const ACTION_SET_ORG = 'ACTION_SET_ORG'
export const ACTION_SET_USER = 'ACTION_SET_USER'
export const ACTION_ADD_NOTIFICATION = 'ACTION_ADD_NOTIFICATION'
export const ACTION_DELETE_NOTIFICATION = 'ACTION_DELETE_NOTIFICATION'
export const ACTION_SET_GUEST_USER = 'ACTION_SET_GUEST_USER'
export const ACTION_SET_SITE_DESIGNS = 'ACTION_SET_SITE_DESIGNS'
