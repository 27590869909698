import React, { useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout'
import { delete_feetbacks, get_feetbacks } from '../../services/api'
import { IFeedback } from '../../models/IApiResponses'
import { addNotification } from '../../store/actions'
import { v4 } from 'uuid'

import DeleteSVG from '../../assets/delete.svg'
import { useDispatch } from 'react-redux'
import { LOCALES } from '../../locales/locales'
import { TLanguages } from '../../models/ILocales'
// import EditSVG from '../../assets/edit.svg'

const EditReviews = () => {
    const dispatch = useDispatch()
    const { hostname, protocol } = new URL(window.location.href)
    const [data, setData] = useState<IFeedback[]>([])
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        get_feetbacks().then(res => {
            setData(res.data)
        })
    }

    const handleDelete = (feedback: IFeedback) => {
        delete_feetbacks(feedback.url.split('/').pop() as string)
            .then(res => {
                console.log(res)
                if (res.status === 204) {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: feedback.title + ' - ' + LOCALES[currentLocale].POLL_REMOVED // need to find and use apropriate string.format package
                        })
                    )
                }
                getData()
            })
            .catch(res => {
                dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: LOCALES[currentLocale].DELETION_ERROR
                    })
                )
            })
    }

    const getURL = (url: string): string => {
        if (hostname === 'localhost') {
            let tempURL = url.split('/').pop()
            return `${protocol}//${hostname}:3000/leave_feedback/${tempURL}`
        }
        return `${protocol}//${url}`
    }
    
    return (
        <MainLayout>
            <div className="overflow-x-auto">
                <table className="table-auto w-full overflow-x-auto">
                    <thead>
                        <tr className="text-md tracking-wide text-center text-gray-900 border-b border-gray-600">
                            <th className="px-4 py-2">{LOCALES[currentLocale].POLL_ID}</th>
                            <th className="px-4 py-2">{LOCALES[currentLocale].POLL_NAME}</th>
                            <th className="px-4 py-2">{LOCALES[currentLocale].POLL_LINK}</th>
                            <th className="px-4 py-2">{LOCALES[currentLocale].POLL_ACTIONS}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, key) => (
                            <tr
                                className="hover:bg-blue-100 transition-all"
                                key={key}
                            >
                                <td className="px-4 py-2 border text-center">
                                    {item.id}
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    {item.title}
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    <a
                                        href={`${getURL(item.url)}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {`${getURL(item.url)}`}
                                    </a>
                                </td>
                                <td className="px-4 py-2 text-center border">
                                    {/*<button className="w-4 inline mr-4">*/}
                                    {/*    <img*/}
                                    {/*        src={EditSVG}*/}
                                    {/*        alt="Редактировать"*/}
                                    {/*    />*/}
                                    {/*</button>*/}
                                    <button
                                        className="w-4 inline"
                                        onClick={() => handleDelete(item)}
                                    >
                                        <img src={DeleteSVG} alt={LOCALES[currentLocale].REMOVE_LABEL} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </MainLayout>
    )
}

export default EditReviews
