import React, { useEffect, useState } from 'react'
import MainLayout from '../layout/MainLayout'
import { Doughnut } from 'react-chartjs-2'
import Loader from '../components/Loader'
import { get_feetbacks, get_feetbacks_by_id } from '../services/api'
import { IFeedback } from '../models/IApiResponses'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'


const FeedbackStatistics = () => {
    const [loading, setLoading] = useState(true)
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [feedbackData, setFeedbackData] = useState<number[]>([0, 0, 0, 0, 0])
    const [data, setData] = useState({
      labels: [
        LOCALES[currentLocale].FEEDBACK_STATUS_A,
        LOCALES[currentLocale].FEEDBACK_STATUS_B,
        LOCALES[currentLocale].FEEDBACK_STATUS_C,
        LOCALES[currentLocale].FEEDBACK_STATUS_D,
        LOCALES[currentLocale].FEEDBACK_STATUS_E
      ],
      datasets: [
        {
          label: LOCALES[currentLocale].RAITINGS,
          data: [0, 0, 0, 0, 0],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)'
          ],
          borderWidth: 1
        }
      ]
    })
    const [feedbacks, setFeedbacks] = useState<IFeedback[]>([])
    const [selectValue, setSelectValue] = useState('9999')
  
    useEffect(() => {
      return () => setFeedbackData([0, 0, 0, 0, 0])
      // eslint-disable-next-line
    }, [])
  
    useEffect(() => {
      updateData()
      // eslint-disable-next-line
    }, [selectValue])
  
    useEffect(() => {
      setData({
        labels: [
          LOCALES[currentLocale].FEEDBACK_STATUS_A,
          LOCALES[currentLocale].FEEDBACK_STATUS_B,
          LOCALES[currentLocale].FEEDBACK_STATUS_C,
          LOCALES[currentLocale].FEEDBACK_STATUS_D,
          LOCALES[currentLocale].FEEDBACK_STATUS_E
        ],
        datasets: [
          {
            label: LOCALES[currentLocale].RAITINGS,
            data: feedbackData.reverse(),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)'
            ],
            borderWidth: 1
          }
        ]
      })
      // eslint-disable-next-line
    }, [feedbackData])
  
    const updateData = () => {
      setLoading(true)
      let tmpData = [0, 0, 0, 0, 0]
      get_feetbacks()
        .then(res => {
          if (res.data.length) {
            setFeedbacks(res.data)
            res.data.map((item, key) => {
              return get_feetbacks_by_id(
                item.url.split('/').pop() as string
              )
                .then(res => {
                  if (
                    Array.isArray(res.data) &&
                    res.data.length
                  ) {
                    return res.data.map(elem => {
                      if (
                        selectValue === '9999' ||
                        elem.feedback.toFixed() ===
                        selectValue.toString()
                      ) {
                        return tmpData[elem.score - 1]++
                      } else {
                        return null
                      }
                    })
                  }
                })
                .finally(() => {
                  setTimeout(() => {
                    setFeedbackData(tmpData)
                  }, 100)
                  if (key === res.data.length - 1) {
                    setTimeout(() => {
                      setLoading(false)
                    }, 200)
                  }
                })
            })
          } else {
            setLoading(false)
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }
  
    const getPercent = (e: number) => {
      if (e === 0) return 0 + '%'
      let sum = 0
      data.datasets[0].data.forEach(item => (sum += item))
      return ((100 / sum) * e).toFixed(0) + '%'
    }
  
    return (
      <MainLayout>
        <div className="w-full h-full flex flex-col flex-wrap justify-center gap-5 overflow-y-auto box-content">
          {loading ? (
            <div className="w-full flex justify-center overflow-hidden">
              <Loader color={'#3B81F6'} />
            </div>
          ) : (
            <div className="pt-10">
              <Doughnut data={data} width={400} height={100} />
              {feedbacks.length ? (
                <select
                  name="feedback"
                  id="feedback"
                  value={selectValue}
                  onChange={e => setSelectValue(e.target.value)}
                  className="p-2 border border-gray-600 rounded-xl"
                >
                  <option value="9999">{LOCALES[currentLocale].ALL}</option>
                  {feedbacks.map(feedback => (
                    <option
                      value={feedback.id}
                      key={feedback.id}
                    >
                      {feedback.title}
                    </option>
                  ))}
                </select>
              ) : null}
              <table className="table-auto w-full">
                <thead>
                  <tr className="text-md tracking-wide text-left text-gray-900 border-b border-gray-600">
                    <th className="px-4 py-2">{LOCALES[currentLocale].RAITINGS}</th>
                    <th className="px-4 py-2">
                      {LOCALES[currentLocale].REVIEWS_AMOUNT}
                    </th>
                    <th className="px-4 py-2">
                      {LOCALES[currentLocale].PERCENT}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.labels.map((item, key) => (
                    <tr
                      key={item}
                      className="hover:bg-blue-100 transition-all"
                    >
                      <td className="px-4 py-2 border">
                        {item}
                      </td>
                      <td className="px-4 py-2 border">
                        {feedbackData[key]}
                      </td>
                      <td className="px-4 py-2 border">
                        {getPercent(feedbackData[key])}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </MainLayout>
    )
}

export default FeedbackStatistics