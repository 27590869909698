import React, { useState } from 'react'

import SortSVG from '../assets/sort.svg'
import FilterSVG from '../assets/filter.svg'
import FilterFillSVG from '../assets/filterFill.svg'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'

interface SortProps {
    field: string
    arr: any[]
    setArr: (e: any[]) => void
    filterValues: any
    setFilterValues: (e: any) => void
}

const Sort: React.FC<SortProps> = ({
    field,
    arr,
    setArr,
    filterValues,
    setFilterValues
}) => {
    const [lastSort, setLastSort] = useState('')
    const [filterShow, setFilterShow] = useState(false)
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const compare = (a: any, b: any, type: 'asc' | 'desc', field: string) => {
        if (a[field] < b[field]) {
            return type === 'asc' ? -1 : 1
        }
        if (a[field] > b[field]) {
            return type === 'asc' ? 1 : -1
        }
        return 0
    }

    const sortHandle = () => {
        setArr([
            ...arr.sort((a, b) =>
                compare(a, b, lastSort === field ? 'desc' : 'asc', field)
            )
        ])
        if (field !== lastSort) {
            setLastSort(field)
        } else {
            setLastSort('')
        }
    }

    return (
        <span className="relative">
            <div
                className={`absolute z-40 bg-white border border-gray-400 rounded-md p-3 ${
                    filterShow ? 'flex' : 'hidden'
                }`}
            >
                <input
                    type="text"
                    className="border-b border-gray-400 z-50"
                    value={filterValues[field]}
                    onChange={e =>
                        setFilterValues({
                            ...filterValues,
                            [field]: e.target.value
                        })
                    }
                />
            </div>
            <img
                src={filterValues[field]?.length ? FilterFillSVG : FilterSVG}
                alt={LOCALES[currentLocale].ALT_TEXT_FILTER}
                className="w-4 cursor-pointer inline-block"
                onClick={() => setFilterShow(!filterShow)}
            />
            <img
                src={SortSVG}
                alt={LOCALES[currentLocale].ALT_TEXT_SORT}
                className="w-4 cursor-pointer inline-block"
                onClick={() => sortHandle()}
            />
            <div
                className={`${
                    filterShow ? 'fixed' : 'hidden'
                } w-full h-full top-0 left-0 z-30`}
                onClick={() => setFilterShow(false)}
            />
        </span>
    )
}

export default Sort
