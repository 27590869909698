
import React from "react";
import classes from './WalSelect.module.css';

const WalSelect: React.FC<any> = ( {value, onChange, defaultValue, options} ) => {

    return (
        <select value={value} className={classes.walSelect} style={{ height: '35px', width: '100%', borderRadius: '4px', padding: '2px 10px'}}
                onChange={event=>onChange(event.target.value)}>
                    {defaultValue ?
                        <option disabled value="">{defaultValue}</option>
                        :<></>}

            {options.map((option: any) => {
                return <option key={option.value} value={option.value}>
                            {option.name}
                        </option>;
                })}
        </select>
    );
};

export default WalSelect;
// export default Button;
