import React, { useEffect, useState } from 'react'
import MainLayout from '../layout/MainLayout'
import { TLanguages } from '../models/ILocales'
import Loader from '../components/Loader'
import { LOCALES } from '../locales/locales'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { get_statistics } from '../services/api'
import { useDispatch } from 'react-redux'
import { addNotification } from '../store/actions'
import { v4 } from 'uuid'

const CardStatistics = () => {
    const [loading, setLoading] = useState(false)
    const [currentLocale] = useState<TLanguages>(
        localStorage.getItem('currentLocale') as TLanguages
    )
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [statisticsData, setStatisticsData] = useState<any>(null)
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const dispatch = useDispatch();

    const handleStartDateChange = (date: Date | null) => {
        if (date) setStartDate(date)
    }

    const handleEndDateChange = (date: Date | null) => {
        if (date) setEndDate(date)
    }

    const handlePeriodButtonClick = (period: string) => {
        const today = new Date()
        let newStartDate = new Date()
        let newEndDate = new Date()

        switch (period) {
            case 'today':
                newStartDate = today
                newEndDate = today
                break
            case 'month':
                newStartDate = new Date(today);
                newEndDate = new Date(today);
                newStartDate.setDate(today.getDate() - 30);
                break
            case 'quarter':
                newStartDate.setMonth(Math.floor(today.getMonth() / 3) * 3, 1)
                break
            case 'year':
                newStartDate.setMonth(0, 1)
                break
            default:
                newStartDate = today
                newEndDate = today
        }
        setSelectedPeriod(period);
        setStartDate(newStartDate)
        setEndDate(newEndDate)

        fetchData(newStartDate, newEndDate)
    }

    const fetchData = async (startDate: Date | null, endDate: Date | null) => {
        setLoading(true)

        try {
            const response = await get_statistics(
                startDate?.toISOString().slice(0, 10) || '',
                endDate?.toISOString().slice(0, 10) || ''
            )
            setStatisticsData(response.data)
        } catch (error) {
          dispatch(
            addNotification({
              id: v4(),
              type: 'Error',
              msg: LOCALES[currentLocale].SERVER_ERROR
            }))
        } finally {
            setLoading(false)
        }
    }

    useEffect(
        () => {
            handlePeriodButtonClick('month')
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const handleRefreshButtonClick = () => {
        fetchData(startDate, endDate)
    }

    return (
        <MainLayout>
            <div className="min-h-40">
                {loading ? (
                    <div className="w-full flex justify-center">
                        <Loader color={'#3B81F6'} />
                    </div>
                ) : (
                    <div className="flex flex-col items-start mb-3">
                        <h1 className="mb-3 ml-2">
                            {LOCALES[currentLocale].SELECT_PERIOD}
                        </h1>
                        <div className=" mb-3">
                            <button
                                className="px-2 py-0 ml-2 text-white rounded"
                                style={{ background: selectedPeriod === 'today' ? '#3B81F6' : '#a3aec2' }}
                                onClick={() => handlePeriodButtonClick('today')}
                            >
                                {LOCALES[currentLocale].TODAY}
                            </button>
                            <button
                                className="px-5 py-0 ml-2 text-white rounded"
                                style={{ background: selectedPeriod === 'month' ? '#3B81F6' : '#a3aec2' }}
                                onClick={() => handlePeriodButtonClick('month')}
                            >
                                {LOCALES[currentLocale].MONTH_}
                            </button>
                            <button
                                className="px-2 py-0 ml-2 text-white rounded"
                                style={{ background: selectedPeriod === 'quarter' ? '#3B81F6' : '#a3aec2' }}
                                onClick={() =>
                                    handlePeriodButtonClick('quarter')
                                }
                            >
                                {LOCALES[currentLocale].QUARTER}
                            </button>
                            <button
                                className="px-2 py-0 ml-2 text-white rounded"
                                style={{ background: selectedPeriod === 'year' ? '#3B81F6' : '#a3aec2' }}
                                onClick={() => handlePeriodButtonClick('year')}
                            >
                                {LOCALES[currentLocale].YEAR}
                            </button>
                        </div>

                        <div className="flex items-center">
                            <span className="mx-2">
                                {LOCALES[currentLocale].FROM}
                            </span>
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                maxDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                className="border border-blue-500 rounded p-1 ml-2"
                            />
                            <span className="mx-2">
                                {LOCALES[currentLocale].UNTIL}
                            </span>
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                maxDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                className="border border-blue-500 rounded p-1"
                            />
                            <button
                                className="px-4 py-1.5 ml-5 bg-blue-500 text-white rounded"
                                onClick={handleRefreshButtonClick}
                            >
                                {LOCALES[currentLocale].REFRESH}
                            </button>
                        </div>
                        <div className="flex mt-5">
                            <div className="flex flex-col ">
                                <span
                                    className="px-4 py-8  mb-2 bg-blue-500 text-white rounded text-center"
                                    style={{
                                        minWidth: '200px',
                                        maxHeight: '87px'
                                    }}
                                >
                                    {LOCALES[currentLocale].GUESTS}
                                </span>
                                <span
                                    className="px-4 py-8  mb-2 bg-blue-500 text-white rounded text-center"
                                    style={{
                                        minWidth: '200px',
                                        maxHeight: '87px'
                                    }}
                                >
                                    {LOCALES[currentLocale].WORKING_WITH_GUESTS}
                                </span>
                                <span
                                    className="px-4 py-8  mb-2 bg-blue-500 text-white rounded text-center"
                                    style={{
                                        minWidth: '200px',
                                        maxHeight: '87px'
                                    }}
                                >
                                    {LOCALES[currentLocale].COMMUNICATION_TYPE}
                                </span>
                                {/* <span className="px-4 py-8  mb-2 bg-blue-500 text-white rounded text-center"  style={{ minWidth: '200px', maxHeight:'87px'}}>По продажам</span>
                                <span className="px-4 py-8  mb-2 bg-blue-500 text-white rounded text-center"  style={{ minWidth: '200px', maxHeight:'87px'}}>По UDS</span>  */}
                            </div>
                            <div className="flex flex-col ">
                                <span
                                    className="px-1 py-2  mb-2 ml-2  border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                    {LOCALES[currentLocale].TOTAL_GUESTS}
                                    <div 
                                     style={{ color: '#a3aec2', fontSize: '40px' }}>
                                        {statisticsData
                                            ? statisticsData.total_guests
                                            : '-'}
                                    </div>
                                </span>
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                   {LOCALES[currentLocale].TOTAL_REVIEWS}
                                    <div 
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.customer_feedbacks_over_period
                                            : '-'}
                                    </div>
                                </span>
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                     {LOCALES[currentLocale].USE_TG_BOT}
                                    <div 
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.total_guest_with_telegram_contacts
                                            : '-'}
                                    </div>
                                </span>
                            </div>
                            <div className="flex flex-col ">
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                   {LOCALES[currentLocale].FOR_SELECTED_PERIOD}
                                    <div 
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.total_guests_over_period
                                            : '-'}
                                    </div>
                                </span>
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px',
                                        
                                    }}
                                >
                                    {LOCALES[currentLocale].REFERRAL_LINK} 
                                    <div
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.registered_guests_with_recommendation
                                            : '-'}
                                    </div>
                                </span>
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                    {LOCALES[currentLocale].USE_APPLE}
                                    <div 
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.total_guest_with_ios_platform
                                            : '-'}
                                    </div>
                                </span>
                            </div>
                            <div className="flex flex-col ">
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                    {LOCALES[currentLocale].PREV_PERIOD}
                                    <div 
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.total_guests_prev_period
                                            : '-'}
                                    </div>
                                </span>
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                    {LOCALES[currentLocale].MESSAGES_DELIVERED}
                                    <div 
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.total_delivered_all_notifications
                                            : '-'}
                                    </div>
                                </span>
                                <span
                                    className="px-1 py-2  mb-2 ml-2 border border-blue-500 rounded text-center"
                                    style={{
                                        width: '250px',
                                        maxHeight: '87px',
                                        fontSize: '14px'
                                    }}
                                >
                                    {LOCALES[currentLocale].USE_GOOGLE}
                                    <div 
                                    style={{ color: '#a3aec2', fontSize: '40px' }}>
                                    {statisticsData
                                            ? statisticsData.total_guest_with_android_platform
                                            : '-'}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </MainLayout>
    )
}

export default CardStatistics
