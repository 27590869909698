import React, { FormEvent, useEffect, useState } from 'react'
import GuestLayout from '../layout/GuestLayout'
import { connect, useDispatch } from 'react-redux'
import { guestAuth } from '../store/actions'
import { IGuestUser, IState } from '../models/IState'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LocaleSelectorHardcoded from '../components/kit/locale-selector-hardcoded/locale-selector-hardcoded'
import '../../node_modules/flag-icons/css/flag-icons.min.css'
import { ISiteDesign } from '../models/IApiResponses'

interface IndexProps {
  guestUser: IGuestUser
  isUserRequestLoading: boolean
  siteDesigns: ISiteDesign | null
}

const Index: React.FC<IndexProps> = ({ guestUser, isUserRequestLoading, siteDesigns }) => {
  const dispatch = useDispatch()
  const [phone, setPhone] = useState('')
  const [currentLocale, setCurrentLocale] = useState<TLanguages>(
    'ua_UA' || 'en_EN' || 'ru_RU'
  )
  const [phoneDefaultCountry, setPhoneDefaultCountry] = useState<string>('')

  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    const storagedLocale = localStorage.getItem('currentLocale')
    switch (storagedLocale) {
      case 'ua_UA':
        setCurrentLocale('ua_UA')
        setPhoneDefaultCountry('ua')
        break
      case 'ru_RU':
        setCurrentLocale('ru_RU')
        setPhoneDefaultCountry('ru')
        break
      case 'en_EN':
        setCurrentLocale('en_EN')
        setPhoneDefaultCountry('us')
        break
      default:
        setCurrentLocale('ua_UA')
        setPhoneDefaultCountry('ua')
        break
    }
    //eslint-disable-next-line
  }, [])

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validatePhone(phone)) {
      setShowWarning(true)
      return
    }
    dispatch(guestAuth(phone))
  }

  const handleParentFun = (value: any) => {
    localStorage.setItem('currentLocale', value)
    switch (value) {
      case 'ua_UA':
        setCurrentLocale('ua_UA')
        setPhoneDefaultCountry('ua')
        break
      case 'ru_RU':
        setCurrentLocale('ru_RU')
        setPhoneDefaultCountry('ru')
        break
      case 'en_EN':
        setCurrentLocale('en_EN')
        setPhoneDefaultCountry('us')
        break
      default:
        setCurrentLocale('ua_UA')
        setPhoneDefaultCountry('ua')
        break
    }
  }

  const handlePhoneChange = (p: string) => {
    if (p) {
      const digitsOnly = p.replace(/\D/g, '')
      setPhone(digitsOnly)
      setShowWarning(false)
    }
  }

  const validatePhone = (p: string) => {
    if (phoneDefaultCountry === 'ua_UA' || 'ru_RU' || 'en_EN') {
      const minPhoneLength = 11
      return p.length >= minPhoneLength
    }
    return true
  }

  return (
    <GuestLayout>
      <form
        onSubmit={handleFormSubmit}
        className="flex flex-col gap-3 align-items-center"
        style={{ height: '100%' }}
      >
        <LocaleSelectorHardcoded
          handleParentFun={(value: any) => {
            handleParentFun(value)
          }}
        ></LocaleSelectorHardcoded>
        {guestUser?.id ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                margin: 'auto'
              }}
            >
              <div
                className="text-white text-4xl w-64 text-center font-bold my-3 "
                style={{
                  margin: 'auto',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {guestUser?.is_show_name ? (
                  <h3
                    
                    style={{
                      color: guestUser?.title_color,
                      textShadow: guestUser?.text_shadow ? '1px 1px 2px black, 0em 0em 6em #000000' : 'none',
                      minWidth: '370px',
                      maxWidth: '600px',
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      margin: '10px',
                      fontSize: '30px'
                    }}
                  >
                    {guestUser?.org_name}
                  </h3>
                ) : (
                  <></>
                )}
                <h3
                  
                  style={{ 
                    color: guestUser?.title_color,
                    textShadow: guestUser?.text_shadow ? '1px 1px 2px black, 0em 0em 6em #000000' : 'none',
                    minWidth: '370px',
                    maxWidth: '600px',
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    margin: '10px',
                   }}
                >
                  {guestUser?.title}
                </h3>
              </div>

              <PhoneInput
                country={phoneDefaultCountry}
                enableAreaCodes={true}
                regions={['eu-union', 'ex-ussr', 'america', 'north-america', 'south-america', 'central-america', 'carribean', 'ex-yugos', 'baltic', 'middle-east', 'north-africa', 'asia']}
                value={phone}
                onChange={handlePhoneChange}
              />
              {showWarning && !validatePhone(phone) && (
                <p
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    color: 'red',
                    textShadow:
                      '2px 2px 4px rgba(0, 0, 0, 0.5)'
                  }}
                >
                  {
                    LOCALES[currentLocale]
                      .Please_enter_valid_phone_number
                  }
                </p>
              )}
              <button
                disabled={isUserRequestLoading}
                type="submit"
                style={{
                  backgroundColor:
                      (siteDesigns &&
                          siteDesigns.registration_button_color) ||
                      '#4f46e5'
              }}
                onClick={() => setShowWarning(true)}
                className="btn-request-init group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isUserRequestLoading ? (
                  <div className="lds-mini-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  LOCALES[currentLocale].USER_ENTER
                )}
              </button>
            </div>
          </>
        ) : null}
      </form>
    </GuestLayout>
  )
}

function mapStateToProps(state: IState) {
  return {
    guestUser: state.guestUser,
    isUserRequestLoading: state.isUserRequestLoading,
    siteDesigns:state.siteDesigns
  }
}

export default connect(mapStateToProps)(Index)
