import React, { FormEvent, useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout'
import {
    get_wallet_designs,
    patch_wallet_designs,
    post_wallet_designs
} from '../../services/api'
import { IState, OrgData } from '../../models/IState'
import { connect, useDispatch } from 'react-redux'
import { IWalletDesign } from '../../models/IApiResponses'
import { addNotification } from '../../store/actions'
import { v4 } from 'uuid'
import { Redirect } from 'react-router-dom'

import InfoPNG from '../../assets/information.png'
import QRCode from 'react-qr-code'
import { LOCALES } from '../../locales/locales'
import { TLanguages } from '../../models/ILocales'
import{ SketchPicker } from 'react-color'

interface PayDesignProps {
    orgData: OrgData
}

const WalletDesign: React.FC<PayDesignProps> = ({ orgData }) => {
    const dispatch = useDispatch()
    const [files, setFiles] = useState({})
    const [redirect, setRedirect] = useState(false)
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [data, setData] = useState<IWalletDesign>({
        id: 1,
        description: '',
        notification_text: '',
        notification_field: '',
        background_color: '',
        foreground_color: '',
        label_color: '',
        logo_text: '',
        header_title: '',
        header_text: '',
        primary_title: '',
        primary_text: '',
        secondary_title: '',
        secondary_text: '',
        auxiliary_title: '',
        auxiliary_text: '',
        barcode: 'q',
        alt_text: '',
        icon: '',
        strip: null,
        logo: '',
        org: orgData.orgId,
        back_fields: [],
        //
        title: '',
        title_color: '',
        is_show_name: true
    })
    const [newPay, setNewPay] = useState(false)
    const [currentPicker, setCurrentPicker] = useState<string>(''); 
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const [selectedBackgroundColor, setSelectedBackgroundColor] = useState<string>('#ffffff');
    const [selectedForegroundColor, setSelectedForegroundColor] = useState<string>('#ffffff');
    const [selectedLabelColor, setSelectedLabelColor] = useState<string>('#ffffff');



    const handleTogglePicker = (e: React.MouseEvent<HTMLButtonElement>, pickerType: string) => {
        e.preventDefault(); 
        setIsPickerOpen(!isPickerOpen);
        setCurrentPicker(pickerType);
    };

    const handleBackgroundColorChange = (color: any) => {
        setSelectedBackgroundColor(color.hex);
        setData(prevData => ({
            ...prevData,
            background_color: color.hex
        }));
    };

    const handleForegroundColorChange = (color: any) => {
        setSelectedForegroundColor(color.hex);
        setData(prevData => ({
            ...prevData,
            foreground_color: color.hex
        }));
    };

    const handleLabelColorChange = (color: any) => {
        setSelectedLabelColor(color.hex);
        setData(prevData => ({
            ...prevData,
            label_color: color.hex
        }));
    };

    useEffect(() => {
        getWalletDesigns()
        // eslint-disable-next-line
    }, [])

    const fileChange = (e: any, field: string) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = () => {
            setData({ ...data, [`${field}`]: reader.result })
            setFiles({ ...files, [`${field}`]: file })
            const formData = new FormData()
            formData.append('logo', file)
            console.log(formData.getAll('logo'))
        }
    }

    const getWalletDesigns = () => {
        get_wallet_designs()
            .then(res => {
                if (res?.data[0]) {
                    setData(res.data[0])
                } else {
                    setNewPay(true)
                }
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: e.response.data.detail
                    })
                )
            })
    }

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (newPay) {
            post_wallet_designs(data.id, files, data)
                .then(() => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].DESIGN_CREATION_SUCCESS
                        })
                    )
                    setRedirect(true)
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e?.response?.data?.detail
                        })
                    )
                })
        } else {
            patch_wallet_designs(data.id, files, data)
                .then(() => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].DESIGN_UPDATING_SUCCESS
                        })
                    )
                    setRedirect(true)
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e?.response?.data?.detail
                        })
                    )
                })
        }
    }

    if (redirect) {
        return <Redirect to="/admin/design" />
    }

    // @ts-ignore
    return (
        <MainLayout>
            <div className="flex lg:flex-row-reverse flex-col-reverse gap-4 h-full overflow-auto">
                <div className="flex flex-col items-center mb-5">
                    <div
                        className="wallet flex flex-col items-center rounded-xl border"
                        style={{
                            backgroundColor:
                                data.background_color.length > 3
                                    ? data.background_color
                                    : '#fff',
                            color:
                                data.foreground_color.length > 3
                                    ? data.foreground_color
                                    : '#000'
                        }}
                    >
                        <div className="flex flex-col w-full h-full justify-between">
                            <div>
                                <div className="w-full flex flex-row px-5 text-xl">
                                    <div
                                        className="w-20 h-20 m-2"
                                        style={{
                                            backgroundImage: `url('${data.logo}')`
                                        }}
                                    />
                                    <div className="flex flex-col items-end"
                                    style={{
                                        flex: '1 1 auto',
                                        padding: '5px 0'
                                    }}>
                                        <span
                                            className="text-sm"
                                            style={{
                                                color:
                                                    data.label_color.length > 3
                                                        ? data.label_color
                                                        : '#000'
                                            }}
                                        >
                                            {data.header_title}
                                        </span>
                                        <span className="text-base">
                                            {data.header_text}
                                        </span>
                                        <span className="text-base"
                                        style={{
                                            fontWeight: 500,
                                            alignSelf: 'self-start'
                                        }}>
                                            {data.logo_text}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="h-32 w-full flex flex-col p-1"
                                    style={{
                                        backgroundImage: `url('${data.strip}')`
                                    }}
                                >
                                    <span className="text-4xl">
                                        {data.primary_text}
                                    </span>
                                    <span
                                        className="text-2xl"
                                        style={{
                                            color:
                                                data.label_color.length > 3
                                                    ? data.label_color
                                                    : '#000'
                                        }}
                                    >
                                        {data.primary_title}
                                    </span>
                                </div>
                                <div className="flex flex-row justify-between p-1">
                                    <div className="flex flex-col p-1">
                                        <span
                                            className="text-sm"
                                            style={{
                                                color:
                                                    data.label_color.length > 3
                                                        ? data.label_color
                                                        : '#000'
                                            }}
                                        >
                                            {data.secondary_title}
                                        </span>
                                        <span>{data.secondary_text}</span>
                                    </div>
                                    <div className="flex flex-col p-1">
                                        <span
                                            className="text-sm"
                                            style={{
                                                color:
                                                    data.label_color.length > 3
                                                        ? data.label_color
                                                        : '#000'
                                            }}
                                        >
                                            {data.auxiliary_title}
                                        </span>
                                        <span>{data.auxiliary_text}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="self-center">
                                <div className="bg-white p-3 rounded-2xl flex flex-col gap-1 items-center" style={{ color: '#000'}}>
                                    <QRCode
                                        value="5142"
                                        bgColor="#fff"
                                        size={100}
                                    />
                                    {data.alt_text.length ? (
                                        <span>{data.alt_text}</span>
                                    ) : (
                                        <span>5142</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form
                    onSubmit={e => handleFormSubmit(e)}
                    className="w-full flex flex-col gap-4 items-center lg:overflow-y-auto"
                >
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="description"
                            className="w-80 flex gap-1 items-center font-semibold"
                        >
                            {LOCALES[currentLocale].BRIEF_CARD_DESCRIPTION} {' '}
                        </label>
                        <input
                            type="text"
                            id="description"
                            placeholder={LOCALES[currentLocale].BRIEF_CARD_DESCRIPTION}
                            value={data.description}
                            onChange={e =>
                                setData({
                                    ...data,
                                    description: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            required
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="notif" className="w-80 font-semibold">
                            {LOCALES[currentLocale].NOTIFICATION_TEXT}
                        </label>
                        <input
                            type="text"
                            id="notif"
                            placeholder={LOCALES[currentLocale].NOTIFICATION_TEXT}
                            value={data.notification_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    notification_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            required
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="bg"
                            className="w-80 flex gap-1 items-center font-semibold"
                        >
                            {LOCALES[currentLocale].BACKGROUND_COLOR_CODE}
                            <img
                                src={InfoPNG}
                                alt={LOCALES[currentLocale].ALT_TEXT_INFORMATION}
                                className="w-4 h-4"
                                data-bs-toggle="tooltip"
                                title={LOCALES[currentLocale].CARD_BACKGROUND_FORMAT_HINT_TOOLTIP}
                            />
                        </label>
                        <button 
                            onClick={(e) => handleTogglePicker(e, 'background')} 
                            className="mx-auto group relative w-80 flex justify-center py-2  border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {LOCALES[currentLocale].CHANGE_SUBMIT_BUTTON}
                        </button>
                        <hr className=" border-gray-500" />
                        <div className="mx-auto"> 
                            {isPickerOpen && currentPicker === 'background' && (
                                <SketchPicker color={selectedBackgroundColor} onChange={handleBackgroundColorChange} />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="txt"
                            className="w-80 flex gap-1 items-center font-semibold"
                        >
                            {LOCALES[currentLocale].TEXT_COLOR_CODE}
                            <img
                                src={InfoPNG}
                                alt={LOCALES[currentLocale].ALT_TEXT_INFORMATION}
                                className="w-4 h-4"
                                data-bs-toggle="tooltip"
                                title={LOCALES[currentLocale].CARD_FORMAT_HINT_TOOLTIP}
                            />
                        </label>
                        <button 
                            onClick={(e) => handleTogglePicker(e,'foreground')} 
                            className="mx-auto group relative w-80 flex justify-center py-2  border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {LOCALES[currentLocale].CHANGE_SUBMIT_BUTTON}
                        </button>
                        <hr className=" border-gray-500" />
                        <div className="mx-auto"> 
                            {isPickerOpen && currentPicker === 'foreground' && (
                                <SketchPicker color={selectedForegroundColor} onChange={handleForegroundColorChange} />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="txtTitle"
                            className="w-80 flex gap-1 items-center font-semibold"
                        >
                            {LOCALES[currentLocale].HEADER_COLOR_CODE}
                            <img
                                src={InfoPNG}
                                alt={LOCALES[currentLocale].ALT_TEXT_INFORMATION}
                                className="w-4 h-4"
                                data-bs-toggle="tooltip"
                                title={LOCALES[currentLocale].CARD_HEADER_HINT_TOOLTIP}
                            />
                        </label>
                        <button 
                            onClick={(e) => handleTogglePicker(e,'label')} 
                            className="mx-auto group relative w-80 flex justify-center py-2  border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {LOCALES[currentLocale].CHANGE_SUBMIT_BUTTON}
                        </button>
                        <hr className=" border-gray-500" />
                        <div className="mx-auto"> 
                            {isPickerOpen && currentPicker === 'label' && (
                                <SketchPicker color={selectedLabelColor} onChange={handleLabelColorChange} />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="logotxt" className="w-80 font-semibold">
                            {LOCALES[currentLocale].LOGO_TEXT}
                        </label>
                        <input
                            type="text"
                            id="logotxt"
                            placeholder={LOCALES[currentLocale].LOGO_TEXT}
                            value={data.logo_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    logo_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="header_title"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].TOP_FIELD_TITLE}
                        </label>
                        <input
                            type="text"
                            id="header_title"
                            placeholder={LOCALES[currentLocale].TOP_FIELD_TITLE}
                            value={data.header_title}
                            onChange={e =>
                                setData({
                                    ...data,
                                    header_title: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="header_text"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].TOP_FIELD_TEXT}
                        </label>
                        <input
                            type="text"
                            id="header_text"
                            placeholder={LOCALES[currentLocale].TOP_FIELD_TEXT}
                            value={data.header_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    header_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="primary_title"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].MAIN_FIELD_TITLE}
                        </label>
                        <input
                            type="text"
                            id="primary_title"
                            placeholder={LOCALES[currentLocale].MAIN_FIELD_TITLE}
                            value={data.primary_title}
                            onChange={e =>
                                setData({
                                    ...data,
                                    primary_title: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="primary_text"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].MAIN_FIELD_TEXT}
                        </label>
                        <input
                            type="text"
                            id="primary_text"
                            placeholder={LOCALES[currentLocale].MAIN_FIELD_TEXT}
                            value={data.primary_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    primary_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="secondary_title"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].SECONDARY_FIELD_TITLE}
                        </label>
                        <input
                            type="text"
                            id="secondary_title"
                            placeholder={LOCALES[currentLocale].SECONDARY_FIELD_TITLE}
                            value={data.secondary_title}
                            onChange={e =>
                                setData({
                                    ...data,
                                    secondary_title: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="secondary_text"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].SECONDARY_FIELD_TEXT}
                        </label>
                        <input
                            type="text"
                            id="secondary_text"
                            placeholder={LOCALES[currentLocale].SECONDARY_FIELD_TEXT}
                            value={data.secondary_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    secondary_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="auxiliary_title"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].ADDITIONAL_FIELD_TITLE}
                        </label>
                        <input
                            type="text"
                            id="auxiliary_title"
                            placeholder={LOCALES[currentLocale].ADDITIONAL_FIELD_TITLE}
                            value={data.auxiliary_title}
                            onChange={e =>
                                setData({
                                    ...data,
                                    auxiliary_title: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="auxiliary_text"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].ADDITIONAL_FIELD_TEXT}
                        </label>
                        <input
                            type="text"
                            id="auxiliary_text"
                            placeholder={LOCALES[currentLocale].ADDITIONAL_FIELD_TEXT}
                            value={data.auxiliary_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    auxiliary_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="alt_text"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].ALTERNATIVE_TEXT}
                        </label>
                        <input
                            type="text"
                            id="alt_text"
                            placeholder={LOCALES[currentLocale].ALTERNATIVE_TEXT}
                            value={data.alt_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    alt_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="icon" className="w-80 font-semibold">
                            {LOCALES[currentLocale].ICON}
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={!data?.icon?.length || files?.icon}
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.icon}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.icon?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="icon"
                                placeholder={LOCALES[currentLocale].ICON}
                                onChange={e => fileChange(e, 'icon')}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full text-black"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="strip"
                            className="w-80 flex gap-1 items-center font-semibold"
                        >
                            {LOCALES[currentLocale].CENTRAL_IMAGE}
                            <img
                                src={InfoPNG}
                                alt="Информация"
                                className="w-4 h-4"
                                data-bs-toggle="tooltip"
                                title={LOCALES[currentLocale].CENTRAL_IMAGE_HINT}
                            />
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={!data?.strip?.length || files?.strip}
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.strip}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.strip?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="strip"
                                placeholder={LOCALES[currentLocale].CENTRAL_IMAGE}
                                onChange={e => fileChange(e, 'strip')}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full text-black"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="logo"
                            className="w-80 flex gap-1 items-center font-semibold"
                        >
                            {LOCALES[currentLocale].LOGOTYPE}
                            <img
                                src={InfoPNG}
                                alt={LOCALES[currentLocale].ALT_TEXT_INFORMATION}
                                className="w-4 h-4"
                                data-bs-toggle="tooltip"
                                title={LOCALES[currentLocale].LOGOTYPE_HINT}
                            />
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={!data?.logo?.length || files?.logo}
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.logo}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.logo?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="logo"
                                placeholder={LOCALES[currentLocale].LOGOTYPE}
                                onChange={e => fileChange(e, 'logo')}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full text-black"
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="group relative w-80 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {LOCALES[currentLocale].CHANGE_SUBMIT_BUTTON}
                    </button>
                </form>
            </div>
        </MainLayout>
    )
}

function mapStateToProps(state: IState) {
    return {
        orgData: state.orgData
    }
}

export default connect(mapStateToProps)(WalletDesign)
