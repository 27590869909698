import React, { ReactElement, useEffect, useState } from 'react'
import { get_site_designs } from '../services/api'
import { ISiteDesign } from '../models/IApiResponses'
import { connect, useDispatch } from 'react-redux'
import { guestAuth, setGuestUser, setSiteDesigns } from '../store/actions'
import Notification from '../components/Notification'
import { INotification, IState } from '../models/IState'

interface GuestLayoutProps {
    children: ReactElement
    notifications: INotification[]
}

const GuestLayout: React.FC<GuestLayoutProps> = ({
    children,
    notifications
}) => {
    const dispatch = useDispatch()
    const [bg, setBg] = useState<ISiteDesign[]>([])

    useEffect(() => {
        get_site_designs().then(res => {
            dispatch(
                setGuestUser({
                    id: res.data[0].id,
                    org: res.data[0].org,
                    org_name: res?.data[0]?.org_name,
                    title: res.data[0].title,
                    title_color: res.data[0].title_color,
                    is_show_name: res.data[0].is_show_name,
                    text_shadow: res.data[0].text_shadow,
                    privacy_policy_link: res.data[0].privacy_policy_link,
                    use_call_check: res.data[0].hasOwnProperty('use_call_check')
                        ? res.data[0].use_call_check!
                        : false
                })
            )
            dispatch(setSiteDesigns(res.data[0]))
            setBg(res.data)
        })
        if (localStorage.getItem('isExsistingUser')) {
            // new flag in localstorage to check if this user is exsisting // not really solution but should help, need test
            // @ts-ignore
            dispatch(guestAuth(localStorage.getItem('phone')))
        }
        //eslint-disable-next-line
    }, [])

    const bgImage = () => {
                return `url('${
            window.innerWidth > 700
                ? bg[0]?.background
                : bg[0]?.background_mobile
        }')`

    }

    return (
        <div className="w-full vh-100 flex justify-center items-center bg-white">
            <div
                className="h-full w-full flex justify-center items-center bg-center bg-no-repeat bg-cover bg-blue-300"
                style={{
                    backgroundImage: bgImage()
                }}
            >
                <div className="absolute top-0 right-0">
                    {notifications.map(item => (
                        <Notification notification={item} key={item.id} />
                    ))}
                </div>
                {children}
            </div>
        </div>
    )
}

function mapStateToProps(state: IState) {
    return {
        notifications: state.notifications
    }
}

export default connect(mapStateToProps)(GuestLayout)
