import React, {useState } from 'react'
import MainLayout from '../layout/MainLayout'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'
import { NavLink } from 'react-router-dom'
import ClientsSVG from '../assets/clients.svg'


interface mainPanels {
  name: string
  link: string
  logo: string
}

const Main = () => {
  const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [mainPanels] = useState<mainPanels[]>([
        {
            name: LOCALES[currentLocale].FEEDBACK_STATISTICS,
            link: '/admin/main/feedback_statistics',
            logo: ClientsSVG
        },
        {
            name: LOCALES[currentLocale].CARD_STATISTICS,
            link: '/admin/main/card_statistics',
            logo: ClientsSVG
        },   
    ])
    return (
      <MainLayout>
          <div className="w-full h-full flex flex-row flex-wrap justify-center gap-3">
              {mainPanels.map(item => (
                  <NavLink
                      to={item.link}
                      className="flex flex-col w-80 h-32 p-5 border-blue-500 border rounded-2xl"
                      key={item.name}
                  >
                      <div className="flex items-start gap-3">
                            <img
                                src={item.logo}
                                alt={item.name}
                                className="w-7 h-7 svg-blue"
                            />
                          <span>{item.name}</span>
                      </div>
                  </NavLink>
              ))}
          </div>
      </MainLayout>
  )
}


export default Main
