import React, { FormEvent, useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { connect, useDispatch } from "react-redux";
import { ICreateNotification } from "../../models/IApiResponses";
import { addNotification } from "../../store/actions";
import { v4 } from "uuid";
import {
	create_notifications,
	get_notification_by_id,
	patch_notifications,
} from "../../services/api";
import DeleteSVG from "../../assets/delete.svg";
import { Redirect, useLocation } from "react-router-dom";
import { IState, OrgData } from "../../models/IState";
import WalInput from "../../components/kit/input/WalInput";
import WalSelect from "../../components/kit/select/WalSelect";
import WalCheckbox from "../../components/kit/checkbox/WalCheckbox";
import { LOCALES } from "../../locales/locales";
import { TLanguages } from "../../models/ILocales";

interface AddTextPushProps {
	orgData: OrgData;
}

const TextPush: React.FC<AddTextPushProps> = ({ orgData }) => {
	const dispatch = useDispatch();
	const query = useQuery();
	const [error, setError] = useState("");
	const [redirect, setRedirect] = useState(false);
	const [editPush, setEditPush] = useState(false);
	const [currentLocale] = useState<TLanguages>(
		localStorage.getItem("currentLocale") as TLanguages,
	);
	const [data] = useState<ICreateNotification>({
		title: "",
		text: "",
		send_time: "",
		period: 0,
		on_birthday: false,
		before_birthday: 0,
		repeat_no_more: 0,
		last_visit: false,
		org: orgData.orgId,
	});
	const [loading, setLoading] = useState(false);

	const [isSopilerOpen, setIsSopilerOpen] = useState<boolean>(false);
	const [fileStr, setFileStr] = useState<string>("");
	const [file, setFile] = useState<any>({});
	const [fileName, setFileName] = useState<string>("");
	const [days, setNewDayState] = useState<any[]>([
		{
			value: 1,
			name: LOCALES[currentLocale].DAY_NAME_SHORT_MON,
			isSelected: true,
		},
		{
			value: 2,
			name: LOCALES[currentLocale].DAY_NAME_SHORT_TUES,
			isSelected: false,
		},
		{
			value: 3,
			name: LOCALES[currentLocale].DAY_NAME_SHORT_WED,
			isSelected: false,
		},
		{
			value: 4,
			name: LOCALES[currentLocale].DAY_NAME_SHORT_THURS,
			isSelected: false,
		},
		{
			value: 5,
			name: LOCALES[currentLocale].DAY_NAME_SHORT_FRI,
			isSelected: false,
		},
		{
			value: 6,
			name: LOCALES[currentLocale].DAY_NAME_SHORT_SAT,
			isSelected: false,
		},
		{
			value: 7,
			name: LOCALES[currentLocale].DAY_NAME_SHORT_SUN,
			isSelected: false,
		},
	]);
	const mappedCategories = orgData.categories.map((category: any) => {
		return {
			uuid: category.uuid,
			name: category.name,
			isSelected: false,
			use: category.use,
		};
	});

	const [categories, setCategoriesState] = useState<any[]>(mappedCategories);
	const [isCategoryGuest, setisCategoryGuest] = useState(false);
	const [isCategoriesInverted] = useState(false);

	const [isBearthdayRepeatingRule, setisBearthdayRepeatingRule] =
		useState(false);
	const [isPushRepeating, setisPushRepeating] = useState(false);

	const mappedSources = orgData.utm_sources.map((source: any) => {
		return {
			created_at: source.created_at,
			id: source.id,
			installs: source.installs,
			source: source.source,
			isSelected: false,
		};
	});
	const [sources, setSources] = useState<any[]>(mappedSources);
	const [isSourcesGuest, setSourcesGuest] = useState(false);
	const [isSourcesInverted] = useState(false);

	const mappedTags = orgData.utm_tags.map((tag: any) => {
		return {
			created_at: tag.created_at,
			id: tag.id,
			installs: tag.installs,
			tag: tag.tag,
			isSelected: false,
		};
	});
	const [tags, setTags] = useState<any[]>(mappedTags);
	const [isTagsGuest, setTagsGuest] = useState(false);
	const [isTagsInverted] = useState(false);

	const [isLongNoVisitGuest, setisLongNoVisitGuest] = useState(false);
	const [newData, setNewData] = useState<any>({
		period: 0,
		before_birthday: "",
		repeat_no_more: 10,
		day: "",
		month: "",
		day_last_visit_from: "",
		day_last_visit_to: "",
		periodStart: "",
		periodEnd: "",
		title: "",
		text: "",
		send_time: "",
		on_birthday: true,
		// last_visit: true,
		org: orgData.orgId,
		categories: [],
		utm_sources: [],
		utm_tags: [],
		// isLongNoVisitGuest: false,
		// isCategoryGuest: false,
		// isBirthdayGuest: fal
		//
		text_sms: "",
		text_push: "",
		text_telegram: "",
		picture_telegram: "",
	});
	useEffect(() => {
		dispatch(
			addNotification({
				id: v4(),
				type: "Info",
				msg: LOCALES[currentLocale].ANDROID_PLATFORM_TEXT_PUSH_WARNING,
			}),
		);
		if (query.get("id")) {
			get_notification_by_id(parseInt(query.get("id") || "0"))
				.then((res) => {
					if (res?.data) {
						var parsedData = res?.data;
						const newParsedData = { ...newData };
						console.log(newParsedData);
						if (parsedData.body && parsedData.body.length > 0) {
							const body = parsedData.body;
							body.forEach((bi: any) => {
								const fields = bi.fields;
								switch (bi.type) {
									case "category":
										const values = bi.fields?.values;
										if (fields && values && values.length > 0) {
											for (let vi = 0; vi < values.length; vi++) {
												const valueItem = values[vi];
												const mappedCategories: any[] = categories.map(
													(item: any, cIndex: number) => {
														let updatedItem = item;
														if (item.uuid === valueItem) {
															updatedItem.isSelected = !updatedItem.isSelected;
														}
														return updatedItem;
													},
												);
												setCategoriesState(mappedCategories);
											}
											setisCategoryGuest(true);
										}
										break;
									case "source":
										const source = bi.fields?.values;
										if (fields && source && source.length > 0) {
											for (let vi = 0; vi < source.length; vi++) {
												const valueItem = source[vi];
												const mappedSources: any[] = sources.map(
													(item: any, cIndex: number) => {
														let updatedItem = item;
														if (item.id === valueItem) {
															updatedItem.isSelected = !updatedItem.isSelected;
														}
														return updatedItem;
													},
												);
												setSources(mappedSources);
											}
											setSourcesGuest(true);
										}
										break;
									case "tags":
										const tag = bi.fields?.values;
										if (fields && tag && tag.length > 0) {
											for (let vi = 0; vi < tag.length; vi++) {
												const valueItem = tag[vi];
												const mappedTags: any[] = tags.map(
													(item: any, cIndex: number) => {
														let updatedItem = item;
														if (item.id === valueItem) {
															updatedItem.isSelected = !updatedItem.isSelected;
														}
														return updatedItem;
													},
												);
												setTags(mappedTags);
											}
											setTagsGuest(true);
										}
										break;
									case "period":
										setisPushRepeating(true);
										if (fields) {
											switch (fields.type) {
												case 0:
													newParsedData.repeat_no_more = fields.repeat_no_more;
													newParsedData.period = fields.type;
													break;
												case 1:
													const values = fields.values;
													if (values && values.length > 0) {
														for (let vi = 0; vi < values.length; vi++) {
															const valueItem = values[vi] - 1;
															const mappedDays: any[] = days.map(
																(item: any, cIndex: number) => {
																	let updatedItem = item;
																	if (cIndex === valueItem) {
																		updatedItem.isSelected =
																			!updatedItem.isSelected;
																	}
																	return updatedItem;
																},
															);
															setNewDayState(mappedDays);
														}
														newParsedData.repeat_no_more =
															fields.repeat_no_more;
														newParsedData.period = fields.type;
													}
													break;
												case 2:
													newParsedData.repeat_no_more = fields.repeat_no_more;
													newParsedData.day = fields.values;
													newParsedData.period = fields.type;
													break;
												case 3:
													newParsedData.repeat_no_more = fields.repeat_no_more;
													newParsedData.day = fields.values.day;
													newParsedData.month = fields.values.month;
													newParsedData.period = fields.type;
													break;
											}
										}
										break;
									case "last_visit":
										if (fields && fields.values) {
											const values = fields.values;
											setisLongNoVisitGuest(true);
											newParsedData.day_last_visit_from = values.from;
											newParsedData.day_last_visit_to = values.to;
										}
										break;
									case "birthday":
										if (fields && fields.values) {
											const values = fields.values;
											setisPushRepeating(true);
											setisBearthdayRepeatingRule(true);
											newParsedData.before_birthday =
												values.days === 0 ? "" : values.days;
											newParsedData.on_birthday = values.days === 0;
										}
										break;
								}
							});
						}
						newParsedData.title = parsedData.title;
						newParsedData.text = parsedData.text;
						newParsedData.text_sms = parsedData.text_sms;

						newParsedData.text_push = parsedData.text_push;
						newParsedData.text_telegram = parsedData.text_telegram;
						newParsedData.picture_telegram =
							parsedData.picture_telegram?.toString();
						newParsedData.send_time = parsedData.sendTime;
						newParsedData.periodStart = parsedData.periodStart;
						newParsedData.periodEnd = parsedData.periodEnd;
						setFileStr(newParsedData.picture_telegram?.toString());
						setFile(newParsedData.picture_telegram?.toString());
						setNewData({
							...newData,
							title: newParsedData.title,
							text: newParsedData.text,
							text_sms: newParsedData.text_sms,
							text_push: newParsedData.text_push,
							text_telegram: newParsedData.text_telegram,
							picture_telegram: newParsedData.picture_telegram,
							send_time: newParsedData.send_time,
							periodStart: newParsedData.periodStart,
							periodEnd: newParsedData.periodEnd,
							period: newParsedData.period,
							before_birthday: newParsedData.before_birthday,
							on_birthday: newParsedData.on_birthday,
							repeat_no_more: newParsedData.repeat_no_more,
							day: newParsedData.day,
							month: newParsedData.month,
							day_last_visit_from: newParsedData.day_last_visit_from,
							day_last_visit_to: newParsedData.day_last_visit_to,
						});
					}
				})
				.catch((e) => {
					dispatch(
						addNotification({
							id: v4(),
							type: "Error",
							msg: e?.response?.data?.detail,
						}),
					);
				});
			setEditPush(true);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setError("");
	}, [data]);
	const fileChange = (e: any, field: string) => {
		if (e.target && e.target.files.length > 0) {
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setNewData({ ...newData, [`${field}`]: reader.result });
				if (reader.result) {
					setFileStr(reader.result.toString());
					setFile(file);
					setFileName(file.name);
				}
			};
		}
	};

	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		let selectedCategories = [];

		if (isCategoriesInverted) {
			selectedCategories = categories
				.filter((category: any) => !category.isSelected)
				.map((category: any) => {
					return category.uuid;
				});
		} else {
			selectedCategories = categories
				.filter((category: any) => category.isSelected)
				.map((category: any) => {
					return category.uuid;
				});
		}
		let selectedSources = [];

		if (isSourcesInverted) {
			selectedSources = sources
				.filter((source: any) => !source.isSelected)
				.map((source: any) => {
					return source.id;
				});
		} else {
			selectedSources = sources
				.filter((source: any) => source.isSelected)
				.map((source: any) => {
					return source.id;
				});
		}

		let selectedTags = [];
		if (isTagsInverted) {
			selectedTags = tags
				.filter((tag: any) => !tag.isSelected)
				.map((tag: any) => {
					return tag.id;
				});
		} else {
			selectedTags = tags
				.filter((tag: any) => tag.isSelected)
				.map((tag: any) => {
					return tag.id;
				});
		}

		var timestampStart = Date.parse(newData.periodStart);

		var timestampEnd = Date.parse(newData.periodEnd);

		if (isNaN(timestampStart) === false && isNaN(timestampEnd) === false) {
			// ??
			if (timestampEnd < timestampStart) {
				dispatch(
					addNotification({
						id: v4(),
						type: "Error",
						msg: LOCALES[currentLocale]
							.VALIDATION_ERROR_LAST_DATE_LESS_THAN_START_DATE,
					}),
				);
				return setError(
					LOCALES[currentLocale]
						.VALIDATION_ERROR_LAST_DATE_LESS_THAN_START_DATE,
				);
			}
			const selectedDays = days.filter((day: any) => day.isSelected);
			if (newData.period === 1 && selectedDays.length === 0) {
				dispatch(
					addNotification({
						id: v4(),
						type: "Error",
						msg: LOCALES[currentLocale].Select_at_least_one_day,
					}),
				);
				return setError(LOCALES[currentLocale].Select_at_least_one_day);
			}
		} else {
			dispatch(
				addNotification({
					id: v4(),
					type: "Error",
					msg: LOCALES[currentLocale].VALIDATION_ERROR_DATES_INVALID,
				}),
			);
			return setError(LOCALES[currentLocale].VALIDATION_ERROR_DATES_INVALID);
		}
		let newPush = {
			title: newData.title,
			text: newData.text,
			org: orgData.orgId,
			sendTime: newData.send_time,
			periodStart: newData.periodStart,
			periodEnd: newData.periodEnd,
			text_sms: newData.text_sms,
			text_push: newData.text_push,
			text_telegram: newData.text_telegram,
			picture_telegram: newData.picture_telegram,
			body: Array<any>(),
		};
		if (isCategoryGuest) {
			const bodyItem = {
				type: "category",
				fields: {
					values: selectedCategories,
				},
			};
			newPush.body.push(bodyItem);
		}
		if (isSourcesGuest) {
			const bodyItem = {
				type: "source",
				fields: {
					values: selectedSources,
				},
			};
			newPush.body.push(bodyItem);
		}
		if (isTagsGuest) {
			const bodyItem = {
				type: "tag",
				fields: {
					values: selectedTags,
				},
			};
			newPush.body.push(bodyItem);
		}

		if (isPushRepeating) {
			if (isBearthdayRepeatingRule) {
				const bodyItem = {
					type: "birthday",
					fields: {
						values: {
							days: newData.on_birthday ? 0 : newData.before_birthday,
						},
					},
				};
				newPush.body.push(bodyItem);
			} else {
				const bodyItem = {
					type: "period",
					fields: Object(),
				};
				bodyItem.fields.repeat_no_more = newData.repeat_no_more;
				switch (newData.period) {
					case 0:
						bodyItem.fields.type = 0;
						bodyItem.fields.values = null;
						break;
					case 1:
						bodyItem.fields.type = 1;
						const selectedDays = days
							.filter((day: any) => day.isSelected)
							.map((day: any) => {
								return day.value;
							});
						bodyItem.fields.values = selectedDays;
						break;
					case 2:
						bodyItem.fields.type = 2;
						bodyItem.fields.values = newData.day;
						break;
					case 3:
						bodyItem.fields.type = 3;
						bodyItem.fields.values = {
							month: newData.month,
							day: newData.day,
						};
						break;
					default:
						bodyItem.fields.type = 0;
						bodyItem.fields.values = null;
						break;
				}
				newPush.body.push(bodyItem);
				if (isLongNoVisitGuest) {
					const bodyItem = {
						type: "last_visit",
						fields: {
							values: {
								from: newData.day_last_visit_from,
								to: newData.day_last_visit_to,
							},
						},
					};
					newPush.body.push(bodyItem);
				}
			}
		}
		if (!newPush.title.trim().length && !newPush.text.trim().length) {
			setLoading(false);
			dispatch(
				addNotification({
					id: v4(),
					type: "Error",
					msg: LOCALES[currentLocale].FIELDS_ARE_NOT_FILLED,
				}),
			);
			return setError(LOCALES[currentLocale].FIELDS_ARE_NOT_FILLED);
		}
		if (editPush && query.get("id")) {
			patch_notifications(parseInt(query.get("id") || "0"), newPush, file) // ?? files
				.then((res) => {
					if (res.status === 200 || res.status === 201) {
						dispatch(
							addNotification({
								id: v4(),
								type: "Success",
								// msg: `Пуш - '${res.data.title}' изменен.`// need to find and use apropriate string.format package
								msg:
									res.data.title +
									" - " +
									LOCALES[currentLocale].PUSH_CHANGE_SUCCESS, // need to find and use apropriate string.format package
							}),
						);
						setRedirect(true);
						setLoading(false);
					} else {
						setLoading(false);
						setError(LOCALES[currentLocale].SERVER_ERROR);
						dispatch(
							addNotification({
								id: v4(),
								type: "Error",
								msg: LOCALES[currentLocale].SERVER_ERROR,
							}),
						);
					}
				})
				.catch((e) => {
					setError(LOCALES[currentLocale].SERVER_ERROR);
					setLoading(false);
					dispatch(
						addNotification({
							id: v4(),
							type: "Error",
							msg: e?.response?.data?.detail,
						}),
					);
				});
		} else {
			if (
				newPush.text_sms.trim().length === 0 &&
				newPush.text_push.trim().length === 0 &&
				newPush.text_telegram.trim().length === 0
			) {
				setLoading(false);
				dispatch(
					addNotification({
						id: v4(),
						type: "Error",
						msg: LOCALES[currentLocale].TEXT_FIELD,
					}),
				);
			} else {
				create_notifications(newPush, file)
					.then((res) => {
						if (res.status === 201) {
							dispatch(
								addNotification({
									id: v4(),
									type: "Success",
									msg:
										res.data.title +
										" - " +
										LOCALES[currentLocale].PUSH_CREATION_SUCCESS,
								}),
							);
							setRedirect(true);
							setLoading(false);
						} else {
							setError(LOCALES[currentLocale].SERVER_ERROR);
							setLoading(false);
							dispatch(
								addNotification({
									id: v4(),
									type: "Error",
									msg: LOCALES[currentLocale].SERVER_ERROR,
								}),
							);
						}
					})
					.catch((e) => {
						console.log(e);
						setError(LOCALES[currentLocale].SERVER_ERROR);
						setLoading(false);
						dispatch(
							addNotification({
								id: v4(),
								type: "Error",
								msg: e?.response?.data?.detail,
							}),
						);
					});
			}
		}
	};

	if (redirect) {
		return <Redirect to={`/admin/push${editPush ? "/edit" : ""}`} />;
	}
	const handleSelectAllSource = (selectAll: boolean) => {
		const updatedSources = sources.map((source) => ({
			...source,
			isSelected: selectAll,
		}));
		setSources(updatedSources);
	};
	const handleSelectAllCategories = (selectAll: boolean) => {
		const updatedCategories = categories.map((category) => ({
			...category,
			isSelected: selectAll,
		}));
		setCategoriesState(updatedCategories);
	};
	const handleSelectAllTags = (selectAll: boolean) => {
		const updatedTags = tags.map((tag) => ({
			...tag,
			isSelected: selectAll,
		}));
		setTags(updatedTags);
	};
	const calculateSmsCount = (text: string) => {
		const smsLength = 70;
		const textLength = text.length;
		const smsCount = Math.ceil(textLength / smsLength);
		return smsCount;
	};

	return (
		<MainLayout>
			<div>
				<form
					onSubmit={(e) => handleFormSubmit(e)}
					className="w-full flex flex-col gap-4 items-center"
				>
					<div style={{ width: "100%" }}>
						<div
							style={{
								padding: "10px 0",
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div style={{ flex: "1 1 25%", userSelect: "none" }}>
								<span className="w-80">
									{LOCALES[currentLocale].MESSAGE_HEADER}
								</span>
							</div>
							<div
								style={{
									flex: "1 1 75%",
									display: "flex",
								}}
							>
								<div className="content-wrapper" style={{ width: "100%" }}>
									<WalInput
										type="text"
										maxLength="120"
										placeholder={LOCALES[currentLocale].HEADER}
										value={newData.title}
										onChange={(e: any) =>
											setNewData({
												...newData,
												title: e.target.value,
											})
										}
										className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
										required
									></WalInput>
								</div>
							</div>
						</div>
						<div
							style={{
								padding: "10px 0",
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div style={{ flex: "1 1 25%", userSelect: "none" }}>
								<span className="w-80">
									{
										LOCALES[currentLocale]
											.SENDING_TIME_ACCORDING_TO_PLUS_THREE_TIME_BELT
									}
								</span>
							</div>
							<div
								style={{
									flex: "1 1 75%",
									display: "flex",
								}}
							>
								<div className="content-wrapper" style={{ width: "100px" }}>
									<WalInput
										type="time"
										placeholder={LOCALES[currentLocale].SENDING_TIME}
										value={newData.send_time}
										onChange={(e: any) =>
											setNewData({
												...newData,
												send_time: e.target.value,
											})
										}
										className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
										required
									></WalInput>
								</div>
							</div>
						</div>
						<div
							style={{
								padding: "10px 0",
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div style={{ flex: "1 1 25%", userSelect: "none" }}>
								<span className="w-80">
									{LOCALES[currentLocale].REMINDER_EXPIRATION_DATE}
								</span>
							</div>
							<div
								style={{
									flex: "1 1 75%",
									display: "flex",
								}}
							>
								<div
									className="content-wrapper"
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<WalInput
										style={{ width: "auto" }}
										type="date"
										placeholder={LOCALES[currentLocale].SENDING_TIME}
										value={newData.periodStart}
										onChange={(e: any) =>
											setNewData({
												...newData,
												periodStart: e.target.value,
											})
										}
										className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
										required
									></WalInput>
									<div style={{ padding: "0 15px" }}>-</div>
									<WalInput
										style={{ width: "auto" }}
										type="date"
										placeholder={LOCALES[currentLocale].SENDING_TIME}
										value={newData.periodEnd}
										onChange={(e: any) =>
											setNewData({
												...newData,
												periodEnd: e.target.value,
											})
										}
										className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
										required
									></WalInput>
								</div>
							</div>
						</div>

						<div
							style={{
								padding: "10px 0",
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div style={{ flex: "1 1 25%", userSelect: "none" }}>
								<span className="w-80">
									{LOCALES[currentLocale].MESSAGE_PUSH_TEXT}
								</span>
								<div style={{ color: "grey" }}>
									{newData.text_push.length}/{140}
								</div>
							</div>
							<div
								style={{
									flex: "1 1 75%",
									display: "flex",
								}}
							>
								<div
									className="content-wrapper"
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<span
										className=""
										style={{ padding: "0 10px 0 0", whiteSpace: "nowrap" }}
									>
										{orgData.organization_name}:
									</span>
									<textarea
										cols={10}
										rows={2}
										maxLength={140}
										placeholder={LOCALES[currentLocale].ENTER_PUSH_TEXT}
										value={newData.text_push}
										onChange={(e) =>
											setNewData({
												...newData,
												text_push: e.target.value,
											})
										}
										className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full resize-none"
										required={false}
									/>
								</div>
							</div>
						</div>

						<div
							style={{
								padding: "10px 0",
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div style={{ flex: "1 1 25%", userSelect: "none" }}>
								<span className="w-80">
									{LOCALES[currentLocale].MESSAGE_SMS_TEXT}
								</span>
								<div style={{ color: "grey" }}>
									{newData.text_sms.length}/{1000}/
									{calculateSmsCount(newData.text_sms)} sms
								</div>
							</div>
							<div
								style={{
									flex: "1 1 75%",
									display: "flex",
								}}
							>
								<div
									className="content-wrapper"
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<span
										className=""
										style={{ padding: "0 10px 0 0", whiteSpace: "nowrap" }}
									>
										{orgData.organization_name}:
									</span>
									<textarea
										cols={10}
										rows={2}
										maxLength={1000}
										placeholder={LOCALES[currentLocale].ENTER_SMS_TEXT}
										value={newData.text_sms}
										onChange={(e) => {
											const inputValue = e.target.value;
											const currentLength = inputValue.length;

											if (currentLength <= 1000) {
												setNewData({
													...newData,
													text_sms: inputValue,
												});
											}
										}}
										className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full resize-none"
										required={false}
									/>
								</div>
							</div>
						</div>
						<div
							className="flex flex-col"
							style={{
								padding: "10px 0",
							}}
						>
							<div style={{ flex: "1 1 25%", userSelect: "none" }}></div>
							<div className="flex flex-row">
								<div style={{ flex: "1 1 25%", userSelect: "none" }}>
									<span className="w-80">
										{LOCALES[currentLocale].MESSAGE_TG_TEXT}
									</span>
									<div style={{ color: "grey" }}>
										{newData.text_telegram.length}/{4000}
									</div>
								</div>
								<div
									style={{
										flex: "1 1 75%",
										display: "flex",
									}}
								>
									<div
										className="content-wrapper"
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "row",
										}}
									>
										<span
											className=""
											style={{ padding: "0 10px 0 0", whiteSpace: "nowrap" }}
										>
											{orgData.organization_name}:
										</span>
										<textarea
											cols={10}
											rows={2}
											maxLength={4000}
											placeholder={LOCALES[currentLocale].ENTER_TG_TEXT}
											value={newData.text_telegram}
											onChange={(e) =>
												setNewData({
													...newData,
													text_telegram: e.target.value,
												})
											}
											className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full resize-none"
											required={false}
										/>
									</div>
								</div>
							</div>
							<div className="flex flex-row-reverse">
								<div
									style={{
										flex: "0 0 75%",
										display: "flex",
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									<div
										className="content-wrapper"
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "row",
										}}
									>
										<span
											className=""
											style={{
												padding: "0 10px 0 0",
												whiteSpace: "nowrap",
												visibility: "hidden",
											}}
										>
											{orgData.organization_name}:
										</span>
										<div
											className="previewComponent w-full"
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											<label
												className="group relative w-full flex justify-center mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black border-2 border-blue-500 bg-blue-100 hover:bg-blue-500 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
												onChange={(e) => fileChange(e, "picture_telegram")}
												htmlFor="pictureTelegramInputId"
											>
												<input
													name=""
													type="file"
													id="pictureTelegramInputId"
													hidden
												/>
												{LOCALES[currentLocale].ADD_IMG_MSG_BTN_NAME}
											</label>
											{fileStr ? (
												<>
													<span
														style={{
															display: "flex",
															flexDirection: "row",
															padding: "10px 15px 10px 0",
														}}
													>
														<img
															src={DeleteSVG}
															alt={LOCALES[currentLocale].REMOVE_LABEL}
															style={{
																height: "22px",
																marginRight: "15px",
																cursor: "pointer",
															}}
															onClick={() => {
																setFile("");
																setFileStr("");
																setFileName("");
															}}
														/>
														<span
															style={{
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
															}}
														>
															{fileName}
														</span>
													</span>
													<div
														className={
															isSopilerOpen
																? "imgPreview h-72"
																: "imgPreview h-7"
														}
														onClick={(e) => {
															setIsSopilerOpen(!isSopilerOpen);
														}}
														style={{ position: "relative" }}
													>
														<img
															src={fileStr}
															alt="#"
															className="h-72"
															style={{ paddingBottom: "30px" }}
														/>
														<div
															style={{
																cursor: "pointer",
																height: "30px",
																position: "absolute",
																bottom: "0",
																width: "100%",
																background: "linear-gradient(#ffffff, #f1f1f1)",
																textAlign: "center",
															}}
														>
															{isSopilerOpen ? (
																<span>
																	{LOCALES[currentLocale].HIDE_SPOILER_BTN_NAME}
																</span>
															) : (
																<span>
																	{LOCALES[currentLocale].SHOW_SPOILER_BTN_NAME}
																</span>
															)}
														</div>
													</div>
												</>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/*категории */}
						<div
							style={{
								width: "100%",
								marginBottom: "5px",
							}}
						>
							<div style={{ padding: "5px 0" }}>
								<WalCheckbox
									defaultState={isCategoryGuest}
									value={isCategoryGuest}
									id="isCategoryGuest"
									onChange={(state: any) => {
										setisCategoryGuest(!isCategoryGuest);
									}}
								></WalCheckbox>
								<label htmlFor="isCategoryGuest" style={{ userSelect: "none" }}>
									{LOCALES[currentLocale].TO_GUESTS_WITH_CATEGORIES}
								</label>
							</div>
							{isCategoryGuest ? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<p style={{ borderBottom: "1px solid grey" }}>
										<input
											type="checkbox"
											id="selectAllCategories"
											checked={categories.every(
												(category) => category.isSelected,
											)}
											onChange={(e) =>
												handleSelectAllCategories(e.target.checked)
											}
										/>{" "}
										{LOCALES[currentLocale].Choose_all}
									</p>
									<div
										style={{
											padding: "10px 0",
											display: "flex",
											flexDirection: "row",
											borderBottom: "1px solid grey",
											flexWrap: "wrap",
										}}
									>
										<div style={{ flex: "1 1 15%" }}>
											<span style={{ padding: "10px 0", fontSize: "24px" }}>
												{LOCALES[currentLocale].CATEGORIES}
											</span>
										</div>
										<div
											style={{
												flex: "1 1 85%",
												display: "flex",
												flexDirection: "row",
												flexWrap: "wrap",
												gap: "10px",
											}}
										>
											{categories.map((category: any, index: number) => {
												return (
													<span
														key={"category-key" + category.uuid}
														title={category.name}
														style={{
															flex: "0 0 250px",
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
													>
														<WalCheckbox
															defaultState={category.isSelected}
															value={category.isSelected}
															id={"category" + category.uuid}
															onChange={(state: any) => {
																const mappedCategories: any[] = categories.map(
																	(item: any, cIndex: number) => {
																		let updatedItem = item;
																		if (cIndex === index) {
																			updatedItem.isSelected =
																				!updatedItem.isSelected;
																		}
																		return updatedItem;
																	},
																);
																setCategoriesState(mappedCategories);
															}}
														></WalCheckbox>
														<span>
															<label htmlFor={"category" + category.uuid}>
																{category.name}
															</label>
														</span>
													</span>
												);
											})}
										</div>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
						{/* Источники трафика */}

						<div
							style={{
								width: "100%",
								marginBottom: "5px",
							}}
						>
							<div style={{ padding: "5px 0" }}>
								<WalCheckbox
									defaultState={isSourcesGuest}
									value={isSourcesGuest}
									id="isSourcesGuest"
									onChange={(state: any) => {
										setSourcesGuest(!isSourcesGuest);
									}}
								></WalCheckbox>
								<label htmlFor="isSourcesGuest" style={{ userSelect: "none" }}>
									{LOCALES[currentLocale].TO_GUESTS_WITH_SOURCE}
								</label>
							</div>
							{isSourcesGuest ? (
								<div
									style={{
										flex: "1 1 100%",
										flexDirection: "column",
									}}
								>
									<p style={{ borderBottom: "1px solid grey" }}>
										<input
											type="checkbox"
											id="selectAllSources"
											checked={sources.every((source) => source.isSelected)}
											onChange={(e) => handleSelectAllSource(e.target.checked)}
										/>{" "}
										{LOCALES[currentLocale].Choose_all}
									</p>
									<div
										style={{
											padding: "10px 0",
											display: "flex",
											flexDirection: "row",
											borderBottom: "1px solid grey",
											flexWrap: "wrap",
										}}
									>
										<div style={{ flex: "1 1 15%" }}>
											<span style={{ padding: "10px 0", fontSize: "24px" }}>
												{LOCALES[currentLocale].TRAFIC_SOURCES}
											</span>
										</div>
										<div
											style={{
												flex: "1 1 85%",
												display: "flex",
												flexDirection: "row",
												flexWrap: "wrap",
												gap: "10px",
											}}
										>
											{sources.map((source: any, index: number) => {
												return (
													<span
														key={"source-key" + source.id}
														title={source.source}
														style={{
															flex: "0 0 250px",
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
													>
														<WalCheckbox
															defaultState={source.isSelected}
															value={source.isSelected}
															id={"source" + source.id}
															onChange={(state: any) => {
																const mappedSources: any[] = sources.map(
																	(item: any, cIndex: number) => {
																		let updatedItem = item;
																		if (cIndex === index) {
																			updatedItem.isSelected =
																				!updatedItem.isSelected;
																		}
																		return updatedItem;
																	},
																);
																setSources(mappedSources);
															}}
														></WalCheckbox>
														<span>
															<label htmlFor={"source" + source.id}>
																&nbsp;{source.source}
															</label>
															<label
																htmlFor={"source" + source.id}
																style={{
																	color: "grey",
																	fontSize: "12px",
																	paddingLeft: "5px",
																}}
															>
																({source.installs})
															</label>
														</span>
													</span>
												);
											})}
										</div>
									</div>
								</div>
							) : (
								<></>
							)}
							{/* тег */}
						</div>
						<div
							style={{
								width: "100%",
								marginBottom: "5px",
							}}
						>
							<div style={{ padding: "5px 0" }}>
								<WalCheckbox
									defaultState={isTagsGuest}
									value={isTagsGuest}
									id="isTagsGuest"
									onChange={(state: any) => {
										setTagsGuest(!isTagsGuest);
									}}
								></WalCheckbox>
								<label htmlFor="isTagsGuest" style={{ userSelect: "none" }}>
									{LOCALES[currentLocale].TO_GUESTS_WITH_TAG}
								</label>
							</div>
							{isTagsGuest ? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<p style={{ borderBottom: "1px solid grey" }}>
										<input
											type="checkbox"
											id="selectAllTags"
											checked={tags.every((tag) => tag.isSelected)}
											onChange={(e) => handleSelectAllTags(e.target.checked)}
										/>{" "}
										{LOCALES[currentLocale].Choose_all}
									</p>
									<div
										style={{
											padding: "10px 0",
											display: "flex",
											flexDirection: "row",
											borderBottom: "1px solid grey",
											flexWrap: "wrap",
										}}
									>
										<div style={{ flex: "1 1 15%" }}>
											<span style={{ padding: "10px 0", fontSize: "24px" }}>
												{LOCALES[currentLocale].TAGS}
											</span>
										</div>
										<div
											style={{
												flex: "1 1 85%",
												display: "flex",
												flexDirection: "row",
												flexWrap: "wrap",
												gap: "10px",
											}}
										>
											{tags.map((tag: any, index: number) => {
												return (
													<span
														key={"tag-key" + tag.id}
														title={tag.tag}
														style={{
															flex: "0 0 250px",
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
													>
														<WalCheckbox
															defaultState={tag.isSelected}
															value={tag.isSelected}
															id={"tag" + tag.id}
															onChange={(state: any) => {
																const mappedTags: any[] = tags.map(
																	(item: any, cIndex: number) => {
																		let updatedItem = item;
																		if (cIndex === index) {
																			updatedItem.isSelected =
																				!updatedItem.isSelected;
																		}
																		return updatedItem;
																	},
																);
																setTags(mappedTags);
															}}
														></WalCheckbox>
														<span>
															<label htmlFor={"tag" + tag.id}>
																&nbsp;{tag.tag}
															</label>
															<label
																htmlFor={"tag" + tag.id}
																style={{
																	color: "grey",
																	fontSize: "12px",
																	paddingLeft: "5px",
																}}
															>
																({tag.installs})
															</label>
														</span>
													</span>
												);
											})}
										</div>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>

						{/* повторять рассылку  */}
						<div style={{ padding: "5px 0" }}>
							<WalCheckbox
								defaultState={isPushRepeating}
								value={isPushRepeating}
								id="isRepeat"
								onChange={(state: any) => {
									setisPushRepeating(!isPushRepeating);
								}}
							></WalCheckbox>
							<label htmlFor="isRepeat" style={{ userSelect: "none" }}>
								{LOCALES[currentLocale].REPEAT_NEWSLETTER}
							</label>
						</div>
						{isPushRepeating ? (
							<div
								className="repeat-rules-container"
								style={{ paddingLeft: "25px" }}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										gap: "25px",
									}}
								>
									<div
										className="radio-container"
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
										}}
									>
										<WalInput
											type="radio"
											name="repeatingTypeBlock"
											value={!isBearthdayRepeatingRule}
											checked={isBearthdayRepeatingRule === false}
											onChange={(e: any) => {
												setisBearthdayRepeatingRule(!isBearthdayRepeatingRule);
											}}
										></WalInput>
									</div>
									<div
										style={{
											flex: "1 1 auto",
											pointerEvents: isBearthdayRepeatingRule ? "none" : "auto",
											opacity: isBearthdayRepeatingRule ? "0.5" : "1",
										}}
									>
										<div>
											<div
												style={{
													width: "100%",
													marginBottom: "5px",
												}}
											>
												{!isBearthdayRepeatingRule ? (
													<div
														style={{
															display: "flex",
															flexDirection: "column",
														}}
													>
														<div
															style={{
																padding: "10px 0",
																display: "flex",
																flexDirection: "row",
															}}
														>
															<div
																style={{ flex: "1 1 25%", userSelect: "none" }}
															>
																{LOCALES[currentLocale].REPETITION_TYPE}
															</div>
															<div
																style={{
																	flex: "1 1 75%",
																	display: "flex",
																	flexDirection: "column",
																	justifyContent: "space-around",
																}}
															>
																<div className="content-wrapper">
																	<WalSelect
																		value={newData.period}
																		onChange={(period: any) => {
																			setNewData({
																				...newData,
																				period: Number(period),
																			});
																		}}
																		defaultValue={
																			LOCALES[currentLocale].REPETITION_TYPE
																		}
																		options={[
																			{
																				value: 0,
																				name: LOCALES[currentLocale]
																					.DAILY_REPETITION_TYPE,
																			},
																			{
																				value: 1,
																				name: LOCALES[currentLocale]
																					.WEEKLY_REPETITION_TYPE,
																			},
																			{
																				value: 2,
																				name: LOCALES[currentLocale]
																					.MONTHLY_REPETITION_TYPE,
																			},
																			{
																				value: 3,
																				name: LOCALES[currentLocale]
																					.ANNUALLY_REPETITION_TYPE,
																			},
																		]}
																	></WalSelect>
																</div>
															</div>
														</div>
														{newData.period === 1 ? (
															<div
																style={{
																	padding: "10px 0",
																	display: "flex",
																	flexDirection: "row",
																}}
															>
																<div
																	style={{
																		flex: "1 1 25%",
																		userSelect: "none",
																	}}
																>
																	{LOCALES[currentLocale].WHAT_DAYS}
																</div>
																<div
																	style={{
																		flex: "1 1 75%",
																		display: "flex",
																		flexDirection: "column",
																		justifyContent: "space-around",
																	}}
																>
																	<div className="content-wrapper">
																		{days.map((day: any, index: number) => {
																			return (
																				<span
																					key={"key" + day.value}
																					style={{ marginRight: "15px" }}
																				>
																					<WalCheckbox
																						defaultState={day.isSelected}
																						value={day.isSelected}
																						id={"day_" + day.value}
																						onChange={(state: any) => {
																							const mappedDays: any[] =
																								days.map(
																									(
																										item: any,
																										cIndex: number,
																									) => {
																										let updatedItem = item;
																										if (cIndex === index) {
																											updatedItem.isSelected =
																												!updatedItem.isSelected;
																										}
																										return updatedItem;
																									},
																								);
																							setNewDayState(mappedDays);
																						}}
																					></WalCheckbox>
																					<span>
																						<label htmlFor={"day_" + day.value}>
																							{day.name}
																						</label>
																					</span>
																				</span>
																			);
																		})}
																	</div>
																</div>
															</div>
														) : (
															<></>
														)}
														{newData.period === 2 ? (
															<div
																style={{
																	padding: "10px 0",
																	display: "flex",
																	flexDirection: "row",
																}}
															>
																<div
																	style={{
																		flex: "1 1 25%",
																		userSelect: "none",
																	}}
																>
																	{LOCALES[currentLocale].DAY_OF_MONTH}
																</div>
																<div
																	style={{
																		flex: "1 1 75%",
																		display: "flex",
																		flexDirection: "column",
																		justifyContent: "space-around",
																	}}
																>
																	<div className="content-wrapper">
																		<WalInput
																			type="text"
																			value={
																				newData.day === 0
																					? ""
																					: newData.day.toString()
																			}
																			onKeyDown={(
																				e: React.KeyboardEvent<HTMLInputElement>,
																			) => {
																				const inputValue =
																					e.currentTarget.value;
																				if (
																					inputValue.length >= 2 &&
																					e.key !== "Backspace" &&
																					e.key !== "Delete"
																				) {
																					e.preventDefault();
																				}
																			}}
																			onChange={(
																				e: React.ChangeEvent<HTMLInputElement>,
																			) => {
																				const inputValue = e.target.value;
																				let newValue = inputValue;

																				if (inputValue !== "") {
																					newValue = Math.max(
																						1,
																						Math.min(
																							31,
																							parseInt(inputValue, 10),
																						),
																					).toString();
																				}

																				setNewData({
																					...newData,
																					day: newValue,
																				});
																			}}
																		></WalInput>
																	</div>
																	<span style={{ fontSize: "14px" }}>
																		(
																		{
																			LOCALES[currentLocale]
																				.MONTH_LENGTH_WARNING
																		}
																		)
																	</span>
																</div>
															</div>
														) : (
															<></>
														)}
														{newData.period === 3 ? (
															<div
																style={{
																	display: "flex",
																	flexDirection: "column",
																}}
															>
																<div
																	style={{
																		padding: "10px 0",
																		display: "flex",
																		flexDirection: "row",
																	}}
																>
																	<div
																		style={{
																			flex: "1 1 25%",
																			userSelect: "none",
																		}}
																	>
																		{LOCALES[currentLocale].MONTH}
																	</div>
																	<div
																		style={{
																			flex: "1 1 75%",
																			display: "flex",
																			flexDirection: "column",
																			justifyContent: "space-around",
																		}}
																	>
																		<div className="content-wrapper">
																			<WalInput
																				type="text"
																				value={
																					newData.month === 0
																						? ""
																						: newData.month.toString()
																				}
																				onKeyDown={(
																					e: React.KeyboardEvent<HTMLInputElement>,
																				) => {
																					const inputValue =
																						e.currentTarget.value;
																					if (
																						inputValue.length >= 2 &&
																						e.key !== "Backspace" &&
																						e.key !== "Delete"
																					) {
																						e.preventDefault();
																					}
																				}}
																				onChange={(
																					e: React.ChangeEvent<HTMLInputElement>,
																				) => {
																					const inputValue = e.target.value;
																					let newValue = inputValue;

																					if (inputValue !== "") {
																						newValue = Math.max(
																							1,
																							Math.min(
																								12,
																								parseInt(inputValue, 10),
																							),
																						).toString();
																					}

																					setNewData({
																						...newData,
																						month: newValue,
																					});
																				}}
																			></WalInput>
																		</div>
																	</div>
																</div>
																<div
																	style={{
																		padding: "10px 0",
																		display: "flex",
																		flexDirection: "row",
																	}}
																>
																	<div
																		style={{
																			flex: "1 1 25%",
																			userSelect: "none",
																		}}
																	>
																		{LOCALES[currentLocale].DAY_OF_MONTH}
																	</div>
																	<div
																		style={{
																			flex: "1 1 75%",
																			display: "flex",
																			flexDirection: "column",
																			justifyContent: "space-around",
																		}}
																	>
																		<div className="content-wrapper">
																			<WalInput
																				type="text"
																				value={
																					newData.day === 0
																						? ""
																						: newData.day.toString()
																				}
																				onKeyDown={(
																					e: React.KeyboardEvent<HTMLInputElement>,
																				) => {
																					const inputValue =
																						e.currentTarget.value;
																					if (
																						inputValue.length >= 2 &&
																						e.key !== "Backspace" &&
																						e.key !== "Delete"
																					) {
																						e.preventDefault();
																					}
																				}}
																				onChange={(
																					e: React.ChangeEvent<HTMLInputElement>,
																				) => {
																					const inputValue = e.target.value;
																					let newValue = inputValue;

																					if (inputValue !== "") {
																						newValue = Math.max(
																							1,
																							Math.min(
																								31,
																								parseInt(inputValue, 10),
																							),
																						).toString();
																					}

																					setNewData({
																						...newData,
																						day: newValue,
																					});
																				}}
																			></WalInput>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															<></>
														)}
														<div
															style={{
																padding: "10px 0",
																display: "flex",
																flexDirection: "row",
															}}
														>
															<div
																style={{ flex: "1 1 25%", userSelect: "none" }}
															>
																{
																	LOCALES[currentLocale]
																		.SEND_TO_THE_SAME_GUESTS_NO_MORE_THAN
																}
															</div>
															<div
																style={{
																	flex: "1 1 75%",
																	display: "flex",
																	flexDirection: "column",
																	justifyContent: "space-around",
																}}
															>
																<div className="content-wrapper">
																	<WalInput
																		type="text"
																		value={
																			newData.repeat_no_more === 0
																				? ""
																				: newData.repeat_no_more.toString()
																		}
																		maxLength={3}
																		onKeyDown={(
																			e: React.KeyboardEvent<HTMLInputElement>,
																		) => {
																			const inputValue = e.currentTarget.value;
																			if (
																				inputValue.length >= 4 &&
																				e.key !== "Backspace" &&
																				e.key !== "Delete"
																			) {
																				e.preventDefault();
																			}
																		}}
																		onChange={(
																			e: React.ChangeEvent<HTMLInputElement>,
																		) => {
																			const inputValue = e.target.value;
																			let newValue = "";

																			if (inputValue !== "") {
																				newValue = Math.max(
																					0,
																					Math.min(
																						365,
																						parseInt(inputValue, 10),
																					),
																				).toString();
																			}

																			setNewData({
																				...newData,
																				repeat_no_more: newValue,
																			});
																		}}
																	></WalInput>
																	<span
																		style={{
																			padding: "0 10px",
																			userSelect: "none",
																		}}
																	>
																		{LOCALES[currentLocale].DAYS}
																	</span>
																</div>
															</div>
														</div>
													</div>
												) : (
													<></>
												)}
											</div>
										</div>
										<div>
											<div
												style={{
													width: "100%",
													marginBottom: "5px",
												}}
											>
												<div style={{ padding: "5px 0" }}>
													{!isBearthdayRepeatingRule ? (
														<WalCheckbox
															defaultState={isLongNoVisitGuest}
															value={isLongNoVisitGuest}
															id="isLongNoVisitGuest"
															onChange={(state: any) => {
																setisLongNoVisitGuest(!isLongNoVisitGuest);
															}}
														></WalCheckbox>
													) : (
														<></>
													)}
													<label
														htmlFor="isLongNoVisitGuest"
														style={{ userSelect: "none" }}
													>
														{
															LOCALES[currentLocale]
																.GUESTS_WHO_VISITED_FOR_THE_LAST_TIME
														}
													</label>
												</div>
												{isLongNoVisitGuest && !isBearthdayRepeatingRule ? (
													<div
														style={{
															display: "flex",
															flexDirection: "column",
														}}
													>
														<div
															style={{
																padding: "10px 0",
																display: "flex",
																flexDirection: "row",
															}}
														>
															<div style={{ flex: "1 1 25%" }}></div>
															<div
																style={{
																	flex: "1 1 75%",
																	display: "flex",
																	flexDirection: "column",
																	justifyContent: "space-around",
																}}
															>
																<div className="content-wrapper">
																	<span
																		style={{
																			padding: "0 10px 0 0",
																			userSelect: "none",
																		}}
																	>
																		{LOCALES[currentLocale].FROM}
																	</span>
																	<WalInput
																		style={{ width: "100px" }}
																		type="text"
																		value={
																			newData.day_last_visit_from === 0
																				? ""
																				: newData.day_last_visit_from.toString()
																		}
																		id={
																			"day_last_visit_from_" +
																			newData.day_last_visit_from
																		}
																		onKeyDown={(
																			e: React.KeyboardEvent<HTMLInputElement>,
																		) => {
																			const inputValue = e.currentTarget.value;
																			if (
																				inputValue.length >= 4 &&
																				e.key !== "Backspace" &&
																				e.key !== "Delete"
																			) {
																				e.preventDefault();
																			}
																		}}
																		onChange={(
																			e: React.ChangeEvent<HTMLInputElement>,
																		) => {
																			const inputValue = e.target.value;
																			let newValue = inputValue;

																			if (inputValue !== "") {
																				newValue = Math.max(
																					1,
																					Math.min(
																						1095,
																						parseInt(inputValue, 10),
																					),
																				).toString();
																			}

																			setNewData({
																				...newData,
																				day_last_visit_from: newValue,
																			});
																		}}
																	></WalInput>
																	<span
																		style={{
																			padding: "0 10px",
																			userSelect: "none",
																		}}
																	>
																		{LOCALES[currentLocale].UNTIL}
																	</span>

																	<WalInput
																		style={{ width: "100px" }}
																		type="text"
																		value={
																			newData.day_last_visit_to === 0
																				? ""
																				: newData.day_last_visit_to.toString()
																		}
																		id={
																			"day_last_visit_from_" +
																			newData.day_last_visit_from
																		}
																		onKeyDown={(
																			e: React.KeyboardEvent<HTMLInputElement>,
																		) => {
																			const inputValue = e.currentTarget.value;
																			if (
																				inputValue.length >= 4 &&
																				e.key !== "Backspace" &&
																				e.key !== "Delete"
																			) {
																				e.preventDefault();
																			}
																		}}
																		onChange={(
																			e: React.ChangeEvent<HTMLInputElement>,
																		) => {
																			const inputValue = e.target.value;
																			let newValue = inputValue;

																			if (inputValue !== "") {
																				newValue = Math.max(
																					1,
																					Math.min(
																						1095,
																						parseInt(inputValue, 10),
																					),
																				).toString();
																			}

																			setNewData({
																				...newData,
																				day_last_visit_to: newValue,
																			});
																		}}
																	></WalInput>
																	<span
																		style={{
																			padding: "0 10px",
																			userSelect: "none",
																		}}
																	>
																		{LOCALES[currentLocale].DAYS_AGO}
																	</span>
																</div>
															</div>
														</div>
													</div>
												) : (
													<></>
												)}
											</div>
										</div>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										gap: "25px",
									}}
								>
									<div
										className="radio-container"
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
										}}
									>
										<WalInput
											type="radio"
											name="repeatingTypeBlock"
											value={isBearthdayRepeatingRule}
											checked={isBearthdayRepeatingRule === true}
											onChange={(e: any) => {
												setisBearthdayRepeatingRule(!isBearthdayRepeatingRule);
											}}
										></WalInput>
									</div>
									<div
										style={{
											flex: "1 1 auto",
											pointerEvents: !isBearthdayRepeatingRule
												? "none"
												: "auto",
											opacity: !isBearthdayRepeatingRule ? "0.5" : "1",
										}}
									>
										<div
											style={{
												width: "100%",
											}}
										>
											<div style={{ padding: "5px 0" }}>
												<label
													htmlFor="isBirthdayGuest"
													style={{ userSelect: "none" }}
												>
													{LOCALES[currentLocale].GUESTS_WHO_HAVE_BIRTHDAY}
												</label>
											</div>
											{isBearthdayRepeatingRule ? (
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														paddingLeft: "25px",
													}}
												>
													<div
														style={{
															padding: "10px 0",
															display: "flex",
															flexDirection: "row",
														}}
													>
														<div
															style={{
																flex: "1 1 75%",
																display: "flex",
																flexDirection: "column",
																justifyContent: "space-around",
															}}
														>
															<div className="content-wrppaer">
																<div
																	style={{
																		display: "flex",
																		flexDirection: "row",
																	}}
																>
																	<div
																		className="radio-container"
																		style={{
																			display: "flex",
																			flexDirection: "column",
																			justifyContent: "center",
																		}}
																	>
																		<WalInput
																			type="radio"
																			name="isBirthdayGuest"
																			value={newData.on_birthday}
																			checked={newData.on_birthday === true}
																			onChange={(e: any) => {
																				setNewData({
																					...newData,
																					on_birthday: !newData.on_birthday,
																				});
																			}}
																		></WalInput>
																	</div>
																	<span
																		style={{
																			padding: "0 10px",
																			userSelect: "none",
																			pointerEvents: !newData.on_birthday
																				? "none"
																				: "auto",
																			opacity: !newData.on_birthday
																				? "0.5"
																				: "1",
																		}}
																	>
																		{LOCALES[currentLocale].IN_BIRTHDAY}
																	</span>
																</div>
																<div
																	style={{
																		display: "flex",
																		flexDirection: "row",
																	}}
																>
																	<div
																		className="radio-container"
																		style={{
																			display: "flex",
																			flexDirection: "column",
																			justifyContent: "center",
																		}}
																	>
																		<WalInput
																			type="radio"
																			name="isBirthdayGuest"
																			value={!newData.on_birthday}
																			checked={newData.on_birthday === false}
																			onChange={(e: any) => {
																				setNewData({
																					...newData,
																					on_birthday: !newData.on_birthday,
																				});
																			}}
																		></WalInput>
																	</div>
																	<div
																		style={{
																			pointerEvents: newData.on_birthday
																				? "none"
																				: "auto",
																			opacity: newData.on_birthday
																				? "0.5"
																				: "1",
																		}}
																	>
																		<span
																			style={{
																				padding: "0 10px",
																				userSelect: "none",
																			}}
																		>
																			{LOCALES[currentLocale].PER}
																		</span>
																		<WalInput
																			style={{ width: "100px" }}
																			type="text"
																			value={
																				newData.before_birthday === 0
																					? ""
																					: newData.before_birthday.toString()
																			}
																			onKeyDown={(
																				e: React.KeyboardEvent<HTMLInputElement>,
																			) => {
																				const inputValue =
																					e.currentTarget.value;
																				if (
																					inputValue.length >= 3 &&
																					e.key !== "Backspace" &&
																					e.key !== "Delete"
																				) {
																					e.preventDefault();
																				}
																			}}
																			onChange={(
																				e: React.ChangeEvent<HTMLInputElement>,
																			) => {
																				const inputValue = e.target.value;
																				let newValue = inputValue;

																				if (inputValue !== "") {
																					newValue = Math.max(
																						1,
																						Math.min(
																							365,
																							parseInt(inputValue, 10),
																						),
																					).toString();
																				}

																				setNewData({
																					...newData,
																					before_birthday: newValue,
																				});
																			}}
																		></WalInput>
																		<span
																			style={{
																				padding: "0 10px",
																				userSelect: "none",
																			}}
																		>
																			{
																				LOCALES[currentLocale]
																					.DAYS_BEFORE_BIRTHDAY
																			}
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
					<button
						disabled={loading}
						type="submit"
						className="group relative w-80 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						{editPush
							? LOCALES[currentLocale].CHANGE_PUSH
							: LOCALES[currentLocale].CREATE_PUSH}
					</button>
					{error.length ? <p className="text-red-600">{error}</p> : null}
				</form>
			</div>
		</MainLayout>
	);
};

function mapStateToProps(state: IState) {
	return {
		orgData: state.orgData,
	};
}

export default connect(mapStateToProps)(TextPush);
