import React, { ReactChild, ReactChildren, useEffect, useState } from 'react'
import { IMenus } from '../models/IMenus'
import { NavLink } from 'react-router-dom'
import ExitSVG from '../assets/exit.svg'
import MenuSVG from '../assets/menu.svg'
import CloseSVG from '../assets/close.svg'
import { INotification, IState, OrgData } from '../models/IState'
import { setAuth } from '../store/actions'
import { connect } from 'react-redux'
import Notification from '../components/Notification'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'

import HomeSVG from '../assets/home.svg'
import ClientsSVG from '../assets/clients.svg'
import DesignSVG from '../assets/design.svg'
import PushSVG from '../assets/push.svg'
import ReviewSVG from '../assets/review.svg'

interface MainLayoutProps {
    children: ReactChildren | ReactChild
    setAuth: (auth: boolean) => void
    orgData: OrgData
    notifications: INotification[]
}

const MainLayout: React.FC<MainLayoutProps> = ({
    children,
    setAuth,
    orgData,
    notifications
}) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(true)
    const [MENU_ITEMS, setMENU_ITEMS] = useState<IMenus[]>([])
    const [currentLocale, setCurrentLocale] = useState<TLanguages>('ru_RU' || 'en_EN')
    useEffect(() => {
        if (window.innerWidth <= 1000) {
            setMenuOpen(false)
        }
        const storageLocale = localStorage.getItem('currentLocale')
        switch (storageLocale) {
            case 'ua_UA':
                setCurrentLocale('ua_UA')
                resetMenuLocales('ua_UA')
                break;
            case 'ru_RU':
                setCurrentLocale('ru_RU')
                resetMenuLocales('ru_RU')
                break;
            case 'en_EN':
                setCurrentLocale('en_EN')
                resetMenuLocales('en_EN')
                break;
            default:
                setCurrentLocale('ua_UA')
                resetMenuLocales('ua_UA')
                break;
        }
        function resetMenuLocales(locale: TLanguages){
            const MENU_ITEMS: IMenus[] = [
                {
                    name: LOCALES[locale].MENU_CONST_HOME,
                    logo: HomeSVG,
                    link: '/admin/main'
                },
                {
                    name: LOCALES[locale].MENU_CONST_DESIGN,
                    logo: DesignSVG,
                    link: '/admin/design'
                },
                {
                    name: LOCALES[locale].MENU_CONST_PUSH_MESSAGES,
                    logo: PushSVG,
                    link: '/admin/push'
                },
                {
                    name: LOCALES[locale].MENU_CONST_REVIEWS,
                    logo: ReviewSVG,
                    link: '/admin/reviews'
                },
                {
                    name: LOCALES[locale].MENU_CONST_CLIENTS,
                    logo: ClientsSVG,
                    link: '/admin/clients'
                },
                {
                    name: LOCALES[locale].MENU_CONST_UTM,
                    logo: PushSVG, 
                    link: '/admin/utm' 
                }
            ]
            setMENU_ITEMS(MENU_ITEMS)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <div className="bg-gradient w-full vh-100 flex justify-center items-center relative">
            <div className="absolute top-0 right-0">
                {notifications.map(item => (
                    <Notification notification={item} key={item.id} />
                ))}
            </div>
            <div className="main-block xl:rounded-2xl lg:shadow-2xl">
                <div className="h-full w-full flex justify-center items-center bg-white xl:rounded-2xl lg:shadow-2xl flex-row">
                    {menuOpen ? (
                        <div className="bg-blue-500 w-72 h-full py-3 pl-6 flex flex-col text-white rounded-l-2xl gap-6 menu">
                            <div className="flex justify-between">
                                <h1 className="text-2xl font-bold">
                                    Get Wallet
                                </h1>
                                <img
                                    src={CloseSVG}
                                    alt={LOCALES[currentLocale].ALT_TEXT_CLOSE_MENU}
                                    onClick={() => setMenuOpen(!menuOpen)}
                                    className="menu-icon svg-white w-7 h-7"
                                />
                            </div>
                            <select name="org" className="bg-blue-500 lg:w-52">
                                <option value={LOCALES[currentLocale].MISTERIOS_COMBOBOX_DEFAULT_VALUE}>
                                    {orgData.org}
                                </option>
                            </select>
                            <ul className="flex flex-col gap-3">
                                {MENU_ITEMS.map((item: IMenus) => (
                                    <li key={item.name}>
                                        <NavLink
                                            to={item.link}
                                            className="flex flex-row items-center gap-2 leading-10 transition-all"
                                            activeClassName="menu-active"
                                        >
                                            <div className="b-rad-first" />
                                            <img
                                                src={item.logo}
                                                alt={item.name}
                                                className="w-5 svg-white"
                                            />
                                            <span>{item.name}</span>
                                            <div className="b-rad-second" />
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                    <div className="w-full h-full flex flex-col py-3 px-5 gap-5 overflow-hidden">
                        <div className="border-b flex justify-between pb-3 gap-3 items-center">
                            <div
                                className="w-7 h-7"
                                onClick={() => setMenuOpen(!menuOpen)}
                            >
                                <img
                                    src={MenuSVG}
                                    alt={LOCALES[currentLocale].ALT_TEXT_MENU}
                                    className="menu-icon"
                                />
                            </div>
                            <div className="flex gap-3 items-center">
                                {orgData.org_status === 'Отключен' && (
                                <span className="p-1 font-bold" style={{ background: 'red' }}>
                                    {orgData.org_status}
                                </span>
                                )}
                                <span>{orgData.user}</span>
                                <img
                                    src={ExitSVG}
                                    alt={LOCALES[currentLocale].ALT_TEXT_EXIT}
                                    className="w-5 cursor-pointer"
                                    onClick={() => setAuth(false)}
                                />
                            </div>
                        </div>
                        <div className="pb-5 flow-root overflow-hidden overflow-y-auto overflow-x-auto min-h-40">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state: IState) {
    return {
        orgData: state.orgData,
        notifications: state.notifications
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        setAuth: (auth: boolean) => dispatch(setAuth(auth))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
