import React, { useEffect, useState } from 'react';
import MainLayout from '../layout/MainLayout';

import { get_utm } from '../services/api';
import Loader from '../components/Loader';
import UTMModal from './UTM/UTMModal';
import UTMSource from './UTM/UTMSource';
import UTMTag from './UTM/UTMTag'; 
import { IUTM } from '../models/IApiResponses';
import { TLanguages } from '../models/ILocales';
import { LOCALES } from '../locales/locales';
import { useDispatch } from 'react-redux';
import { addNotification } from '../store/actions';
import { v4 } from 'uuid';

const UtmPage = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateSource, setCreateSource] = useState(false);
  const [isCreateTag, setCreateTag] = useState(false); 
  const [showTable, setShowTable] = useState(true);
  const [utmData, setUtmData] = useState<IUTM[]>([]);
  const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
  const [notification, setNotification] = useState({ text: '', show: false }); // Добавляем состояние для нотификации
  const dispatch = useDispatch();
  

  useEffect(() => {
    setLoading(true);
    get_utm()
      .then((res) => {
        setUtmData(res.data);
      })
      .catch(() => {
        dispatch(
          addNotification({
            id: v4(),
            type: 'Error',
            msg: LOCALES[currentLocale].SERVER_ERROR
          })
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setCreateSource(false);
    setCreateTag(false); 
    setShowTable(false);
  };

  const handleOpenCreateSource = () => {
    setCreateSource(true);
    setIsModalOpen(false);
    setCreateTag(false); 
    setShowTable(false);
  };

  const handleOpenCreateTag = () => {
    setCreateTag(true); 
    setIsModalOpen(false);
    setCreateSource(false);
    setShowTable(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCreateSource(false);
    setCreateTag(false); 
    setShowTable(true);
    refreshTableData(); 
  };

  const refreshTableData = () => {
    setLoading(true);
    get_utm()
      .then((res) => {
        setUtmData(res.data);
      })
      .catch(() => {
        dispatch(
          addNotification({
            id: v4(),
            type: 'Error',
            msg: LOCALES[currentLocale].SERVER_ERROR
          })
        );
      })
      .finally(() => setLoading(false));
  };
  
  function copyToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  const showNotification = () => {
    const notificationText = LOCALES[currentLocale].LINK_COPIED;
    setNotification({ text: notificationText, show: true });
  
    setTimeout(() => {
      setNotification({ text: '', show: false });
    }, 2000); 
  };

  return (
    <MainLayout>
      <div className="min-h-40">
        {loading ? (
          <div className="w-full flex justify-center">
            <Loader color={'#3B81F6'} />
          </div>
        ) : (
          <div>
            {showTable && (
              <div>
                <div className="flex items-center justify-between mb-3">
                  <button
                    className="flex justify-start bg-blue-500 text-white px-3 py-1 rounded mr-2 "
                    onClick={handleOpenModal}
                  >
                    {LOCALES[currentLocale].CREATE_UTM}
                  </button>
                  <div>
                    <button
                      className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
                      onClick={handleOpenCreateSource}
                    >
                      {LOCALES[currentLocale].CREATE_SOURCE} 
                    </button>
                    <button
                      className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
                      onClick={handleOpenCreateTag}
                    >
                      {LOCALES[currentLocale].CREATE_TAG} 
                    </button>
                  </div>
                </div>
                <table className="table-auto w-full" style={{ textAlign: 'center' }}>
                  <thead>
                    <tr className="text-md tracking-wide  text-gray-900 border-b border-gray-600">
                      <th className="px-2 py-2">{LOCALES[currentLocale].UTM_NAME}</th>
                      <th className="px-2 py-2">{LOCALES[currentLocale].UTM_SOURCE}</th>
                      <th className="px-2 py-2">{LOCALES[currentLocale].UTM_TAG}</th>
                      <th className="px-2 py-2">{LOCALES[currentLocale].UTM_CREATED}</th>
                      <th className="px-2 py-2">{LOCALES[currentLocale].USERS} </th>
                      <th className="px-2 py-2">{LOCALES[currentLocale].LINK}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {utmData.map((item) => (
                      <tr className="hover:bg-blue-100 transition-all" key={item.id}>
                        <td className="px-2 py-2 border">{item.name}</td>
                        <td className="px-2 py-2 border">{item.utm_source.source}</td>
                        <td className="px-2 py-2 border">{item.utm_tag ? item.utm_tag.tag : '-'}</td>
                        <td className="px-2 py-2 border">{item.created_at}</td>
                        <td className="px-2 py-2 border">{item.installs}</td>
                        <td className="px-2 py-2 border" style={{ width: '450px' }}>{item.url}
                          <button
                            style={{ cursor: 'pointer'}}
                            className=" hover:text-blue-900 text-blue-500 rounded "
                            onClick={() => {
                              copyToClipboard(item.url);
                              showNotification();
                            }}
                          >
                            &#10065;
                          </button> 
                          {notification.show && (
                            <div className="bg-blue-500 text-white py-2 px-4 rounded fixed top-4 right-4 z-50">
                              {notification.text}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {isModalOpen && (
              <UTMModal onClose={handleCloseModal} />
            )}
            {isCreateSource && (
              <UTMSource onClose={handleCloseModal} />
            )}
            {isCreateTag && (
              <UTMTag onClose={handleCloseModal} /> 
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default UtmPage;
