import React, { useEffect, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import { Client } from '../models/IApiResponses';
import { get_clients } from '../services/api';
import Loader from '../components/Loader';
import Sort from '../components/Sort';
import { LOCALES } from '../locales/locales';
import { TLanguages } from '../models/ILocales';

const Clients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [initialClients, setInitialClient] = useState<Client[]>([]);
  const [totalClients, setTotalClients] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState<any>({});
  const [currentLocale] = useState<TLanguages>(
    localStorage.getItem('currentLocale') as TLanguages
  );
  const genders = [
    LOCALES[currentLocale].GENDER_DEFAULT,
    LOCALES[currentLocale].GENDER_MALE_SHORT,
    LOCALES[currentLocale].GENDER_FEMALE_SHORT,
  ];


  useEffect(() => {
    setLoading(true);
    get_clients()
      .then((res) => {
        setClients(res.data.results);
        setInitialClient(res.data.results);
        setTotalClients(res.data.count); 
        if (res.data.links.next) {
          loadNextPage(res.data.links.next);
        } else {
          console.log('Все клиенты загружены');
        }
      })
      .catch(() => {
        console.log('err');
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    let arr = initialClients
    Object.keys(filterValues).forEach(field => {
        arr = arr.filter(i =>
            // @ts-ignore
            i[field]
                ?.toString()
                .toLowerCase()
                // @ts-ignore
                .includes(filterValues[field].toString().toLowerCase())
        )
    })
    setClients(arr)
    // eslint-disable-next-line
}, [filterValues])



const loadNextPage = async (nextPageUrl: string | null) => {
    try {
      if (nextPageUrl) {
        const response = await get_clients(nextPageUrl);
        const newClients = response.data.results;
        setClients((prevClients) => [...prevClients, ...newClients]);
        
        if (response.data.links.next) {
          setTimeout(() => {
            loadNextPage(response.data.links.next);
          }, 300); 
        } else {
          console.log('Все клиенты загружены');
        }
      }
    } catch (error) {
      console.error('Ошибка при получении данных: ', error);
    }
};

  return (
    <MainLayout>
      <div className="min-h-40">
        {loading ? (
          <div className="w-full flex justify-center">
            <Loader color={'#3B81F6'} />
          </div>
        ) : (
          <div className="min-h-40">
            <div className="w-full flex justify-between">
              <h3>
                {LOCALES[currentLocale].TOTAL_CLIENTS} {totalClients}
              </h3>
              <span
                className="cursor-pointer"
                onClick={() => setFilterValues({})}
              >
                {LOCALES[currentLocale].CLEAR_FILTERS}
              </span>
            </div>
            {initialClients.length ? (
              <div className="w-full overflow-x-auto overflow-y-auto min-h-40">
                <table className="table-auto w-full">
                  <thead className="min-h-full">
                    <tr className="text-md tracking-wide text-left text-gray-900 border-b border-gray-600 min-h-full">
                      <th className="px-4 py-3 table-head">
                        <span>{LOCALES[currentLocale].MAIN_NAME}</span>
                        <Sort
                          field={'name'}
                          arr={clients}
                          setArr={setClients}
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                        />
                      </th>
                      <th className="px-4 py-3 table-head">
                        <span>{LOCALES[currentLocale].LAST_NAME}</span>
                        <Sort
                          field={'second_name'}
                          arr={clients}
                          setArr={setClients}
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                        />
                      </th>
                      <th className="px-4 py-3 table-head">
                        <span>{LOCALES[currentLocale].SEX}</span>
                        <Sort
                          field={'gender'}
                          arr={clients}
                          setArr={setClients}
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                        />
                      </th>
                      <th className="px-4 py-3 table-head">
                        <span>{LOCALES[currentLocale].PHONE}</span>
                        <Sort
                          field={'phone'}
                          arr={clients}
                          setArr={setClients}
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                        />
                      </th>
                      <th className="px-4 py-3 table-head">
                        <span>Email</span>
                        <Sort
                          field={'email'}
                          arr={clients}
                          setArr={setClients}
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                        />
                      </th>
                      <th className="px-4 py-3 table-head">
                        <span>{LOCALES[currentLocale].BALANCE}</span>
                        <Sort
                          field={'balance'}
                          arr={clients}
                          setArr={setClients}
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.map((item) => (
                      <tr
                        key={item.id}
                        className="hover:bg-blue-100 transition-all"
                      >
                        <td className="px-4 py-3 border">{item.name}</td>
                        <td className="px-4 py-3 border">{item.second_name}</td>
                        <td className="px-4 py-3 border">
                          {genders[item.gender]}
                        </td>
                        <td className="px-4 py-3 border">{item.phone}</td>
                        <td className="px-4 py-3 border">{item.email}</td>
                        <td className="px-4 py-3 border">{item.balance}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Clients;
